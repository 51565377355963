import {
    AskChatRequest,
    AskChatResponse,
    ChatApiService,
    ChatMessageHeader,
    ChatSettings,
    CreateChatSessionRequest,
    CreateChatSessionResponse,
    RetrieveChatSessionResponse,
    RetrieveChatSessionsResponse,
    RetrieveServiceInfoResponse,
    ServiceApiService,
    UpdateChatSessionRequest,
    UpdateChatSessionResponse,
} from 'shared/libs/generated';
import { BaseService } from 'shared/services/BaseService';

export class ChatService extends BaseService {
    public createChatSessionAsync = async (
        title: string,
        personaId: string,
        accessToken: string,
    ): Promise<CreateChatSessionResponse> => {
        this.setAccessToken(accessToken);

        const request: CreateChatSessionRequest = {
            title,
            personaId,
        };

        try {
            return await ChatApiService.createChatSessionEndpoint(request);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveChatSessionAsync = async (
        chatId: string,
        accessToken: string,
    ): Promise<RetrieveChatSessionResponse> => {
        this.setAccessToken(accessToken);
        try {
            return await ChatApiService.retrieveChatSessionEndpoint(chatId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveChatSessionsAsync = async (accessToken: string): Promise<RetrieveChatSessionsResponse> => {
        this.setAccessToken(accessToken);
        try {
            return await ChatApiService.retrieveChatSessionsEndpoint();
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveChatMessagesAsync = async (chatId: string, accessToken: string): Promise<ChatMessageHeader[]> => {
        this.setAccessToken(accessToken);
        try {
            const result = await ChatApiService.retrieveChatMessagesEndpoint(chatId);
            return result.results.reverse();
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }

        // Messages are returned with most recent message at index 0 and oldest message at the last index,
        // so we need to reverse the order for render
    };

    public updateChatSessionAsync = async (
        accessToken: string,
        chatId: string,
        title?: string,
        settings?: ChatSettings,
    ): Promise<UpdateChatSessionResponse> => {
        this.setAccessToken(accessToken);
        const request: UpdateChatSessionRequest = {
            title,
            settings,
        };

        try {
            return await ChatApiService.updateChatSessionEndpoint(chatId, request);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public deleteChatSessionAsync = async (chatId: string, accessToken: string): Promise<void> => {
        this.setAccessToken(accessToken);
        try {
            return ChatApiService.deleteChatSessionEndpoint(chatId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public askChatAsync = async (
        chatId: string,
        request: AskChatRequest,
        accessToken: string,
    ): Promise<AskChatResponse> => {
        this.setAccessToken(accessToken);
        try {
            return await ChatApiService.askChatEndpoint(chatId, request);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveServiceInfoAsync = async (accessToken: string): Promise<RetrieveServiceInfoResponse> => {
        this.setAccessToken(accessToken);
        try {
            return await ServiceApiService.retrieveServiceInfoEndpoint();
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };
}
