import {
    AdminSettingsHeader,
    RetrieveServiceInfoResponse,
    ServiceApiService,
    UpdateAdminSettingRequest,
} from 'shared/libs/generated';
import { BaseService } from 'shared/services/BaseService';

export interface IMaintenanceStatus {
    title: string | null;
    message: string | null;
    note: string | null | undefined;
}

export class ServiceInfoService extends BaseService {
    public retrieveServiceStatusAsync = async (accessToken: string): Promise<void> => {
        this.setAccessToken(accessToken);

        try {
            await ServiceApiService.retrieveServiceStatusEndpoint();
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveServiceInfoAsync = async (accessToken: string): Promise<RetrieveServiceInfoResponse> => {
        this.setAccessToken(accessToken);

        try {
            return await ServiceApiService.retrieveServiceInfoEndpoint();
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveAdminSettingsAsync = async (): Promise<AdminSettingsHeader> => {
        try {
            const result = await ServiceApiService.retrieveSettingsEndpoint();

            return result.settings;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public updateAdminSettingsAsync = async (
        accessToken: string,
        request: UpdateAdminSettingRequest,
    ): Promise<AdminSettingsHeader> => {
        this.setAccessToken(accessToken);

        try {
            const result = await ServiceApiService.updateAdminSettingsEndpoint(request);

            return result.settings;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };
}
