import { Reducer, AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import chatsReducer from 'modules/core/redux/chats/chatsSlice';
import collectionsReducer from 'modules/core/redux/collections/collectionsSlice';
import personasReducer from 'modules/core/redux/personas/personasSlice';
import appReducer from 'shared/redux/features/app/appSlice';
import themeReducer from 'shared/redux/features/theme/themeSlice';
import usersReducer from 'shared/redux/features/users/usersSlice';
import { RootState } from './store';

// Define a top-level root state reset action
export const resetApp = createAction('resetApp');

// Define a root reducer that combines all the reducers
export const rootReducerKeyValues = {
    app: appReducer,
    chat: chatsReducer,
    collections: collectionsReducer,
    users: usersReducer,
    personas: personasReducer,
    theme: themeReducer,
};

// Combine all the reducers
const rootReducer: Reducer<RootState> = combineReducers(rootReducerKeyValues);

// Define a special resetApp reducer that handles resetting the entire state
export const resetAppReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetApp.type) {
        state = {
            app: appReducer(undefined, action),
            chat: chatsReducer(undefined, action),
            collections: collectionsReducer(undefined, action),
            users: usersReducer(undefined, action),
            personas: personasReducer(undefined, action),
            theme: themeReducer(undefined, action),
        };
    }

    return rootReducer(state, action);
};

export default resetAppReducer;
