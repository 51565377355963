import botIcon1 from 'shared/assets/bot-icons/bot-icon-1.png';

export const Constants = {
    app: {
        name: 'DocumentChat',
        CONNECTION_ALERT_ID: 'connection-alert',
        importTypes: '.pdf,.txt', // ,.docx'
    },
    msal: {
        method: 'redirect', // 'redirect' | 'popup'
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
        appScopes: ['openid', 'offline_access', 'profile'],
        // MS Graph scopes required for loading user information
        msGraphAppScopes: ['User.ReadBasic.All'],
    },
    bot: {
        profile: {
            id: 'bot',
            username: 'Assistant',
            email: '',
            photoUrl: botIcon1,
        },
    },
    debug: {
        root: 'document-chat',
    },
    roles: {
        user: 'RSM.AI.ProposalGenerator.User',
        admin: 'RSM.AI.ProposalGenerator.Admin',
        rfx: 'RSM.AI.ProposalGenerator.Rfx',
    },
};
