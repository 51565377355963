/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useMemo, useState } from 'react';
import { Module } from 'modules';
import { FeatureFlagHeader } from 'shared/libs/generated';
import { useAppSelector } from 'shared/redux/app/hooks';
import { store } from 'shared/redux/app/store';

interface Skill {
    name: string;
    title: string;
    component: JSX.Element;
}

interface LoadedModule {
    default: Module;
}

const toKebabCase = (str: keyof FeatureFlagHeader) => {
    switch (str) {
        case 'rfx':
            return 'rfx';
        default:
            return str;
    }
};

const additionalModules = [
    {
        name: 'asx',
    },
];

export const useModules = () => {
    const [skills, setSkills] = useState<Skill[]>([]);
    const [modules, setModules] = useState<Module[]>([]);

    const features = useAppSelector((state) => state.app.serviceInfo.settings.featureFlags);
    const enabledModules = useMemo(() => {
        return Object.entries(features)
            .filter(([_key, value]) => value)
            .map(([key]) => ({ name: key }))
            .concat(additionalModules);
    }, [features]);

    useEffect(() => {
        const currentSkills: Skill[] = [];
        const currentModules: Module[] = [];

        enabledModules.forEach(async (moduleInfo) => {
            try {
                const loadedModule: LoadedModule | null = await import(
                    `modules/${toKebabCase(moduleInfo.name as keyof FeatureFlagHeader)}/index.tsx`
                );

                if (loadedModule == null) {
                    return;
                }

                const module = loadedModule.default;

                currentModules.push(module);

                module.reducers.forEach((reducer) => {
                    store.reducerManager.addReducer(module.name, reducer);
                });

                if (module.skill?.canView()) {
                    currentSkills.push({ name: module.name, title: module.title, component: module.skill.component });
                }
            } catch (error) {
                console.error(`Failed to load module ${moduleInfo.name}:`, error);
            } finally {
                setSkills(currentSkills);
                setModules(currentModules);
            }
        });
    }, [enabledModules]);

    return {
        skills,
        modules,
    };
};
