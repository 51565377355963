import { ApiError, ErrorResponse, OpenAPI } from 'shared/libs/generated';

export const BackendServiceUrl =
    process.env.REACT_APP_BACKEND_URI == null || process.env.REACT_APP_BACKEND_URI.trim() === ''
        ? window.origin
        : process.env.REACT_APP_BACKEND_URI;

export class BaseService {
    constructor(protected readonly serviceUrl: string = BackendServiceUrl) {
        OpenAPI.BASE = serviceUrl;
    }

    setAccessToken(accessToken: string) {
        OpenAPI.HEADERS = {
            Authorization: `Bearer ${accessToken}`,
        };
    }

    processErrorMessage(err: any): Error {
        if (!(err instanceof ApiError)) {
            return new Error('Internal Server Error.');
        }

        if (err.status === 504) {
            return new Error('The request timed out. Please try sending your message again.');
        }

        if (err.status === 403) {
            return new Error('Unauthorized.');
        }

        if (err.status === 400) {
            const responseBody = err.body as ErrorResponse;
            if (responseBody.message === 'One or more errors occurred!') {
                const errorMessages = Object.entries(responseBody.errors)
                    .map(([key, messages]) => `\n${key}: ${messages.join(' ')}`)
                    .join('. ');

                const errorMessage = `One or more validation errors occurred: ${errorMessages}`;

                return new Error(errorMessage);
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/dot-notation
        const errorMessage = `${err.statusText} (${err.status}): ${err.body['reason']}`;

        return new Error(errorMessage);
    }
}
