/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AskRfxChatRequirementsRequest } from '../models/AskRfxChatRequirementsRequest';
import type { AskRfxChatRequirementsResponse } from '../models/AskRfxChatRequirementsResponse';
import type { AskRfxChatResponses } from '../models/AskRfxChatResponses';
import type { AskRfxChatResponsesRequest } from '../models/AskRfxChatResponsesRequest';
import type { CreateRfxChatRequest } from '../models/CreateRfxChatRequest';
import type { CreateRfxChatResult } from '../models/CreateRfxChatResult';
import type { EditRfxChatRequest } from '../models/EditRfxChatRequest';
import type { EditRfxChatResult } from '../models/EditRfxChatResult';
import type { GetRfxChatsResult } from '../models/GetRfxChatsResult';
import type { GetRfxProcessChatMessagesResult } from '../models/GetRfxProcessChatMessagesResult';
import type { GetRfxProcessResponseChatMessagesResult } from '../models/GetRfxProcessResponseChatMessagesResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RfxApiService {
    /**
     * @param id
     * @param requestBody
     * @returns AskRfxChatRequirementsResponse Success
     * @throws ApiError
     */
    public static askRfxChatRequirementsEndpoint(
        id: string,
        requestBody: AskRfxChatRequirementsRequest,
    ): CancelablePromise<AskRfxChatRequirementsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/RFX/chats/{id}/requirements',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AskRfxChatResponses Success
     * @throws ApiError
     */
    public static askRfxChatResponsesEndpoint(
        id: string,
        requestBody: AskRfxChatResponsesRequest,
    ): CancelablePromise<AskRfxChatResponses> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/RFX/chats/{id}/responses',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateRfxChatResult Success
     * @throws ApiError
     */
    public static createRfxChatEndpoint(
        requestBody: CreateRfxChatRequest,
    ): CancelablePromise<CreateRfxChatResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/RFX/Chats',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns GetRfxChatsResult Success
     * @throws ApiError
     */
    public static getRfxChatsEndpoint(): CancelablePromise<GetRfxChatsResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/RFX/Chats',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteRfxChatEndpoint(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/RFX/Chats/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns EditRfxChatResult Success
     * @throws ApiError
     */
    public static editRfxChatEndpoint(
        id: string,
        requestBody: EditRfxChatRequest,
    ): CancelablePromise<EditRfxChatResult> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/RFX/Chats/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns GetRfxProcessChatMessagesResult Success
     * @throws ApiError
     */
    public static getRfxProcessChatMessagesEndpoint(
        id: string,
    ): CancelablePromise<GetRfxProcessChatMessagesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/RFX/chats/{id}/processMessages',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns GetRfxProcessResponseChatMessagesResult Success
     * @throws ApiError
     */
    public static getRfxProcessResponseChatMessagesEndpoint(
        id: string,
    ): CancelablePromise<GetRfxProcessResponseChatMessagesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/RFX/chats/{id}/processResponseMessages',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
