import {
    useId,
    useToastController,
    Toast,
    ToastTitle,
    ToastIntent,
    ToastTrigger,
    makeStyles,
} from '@fluentui/react-components';
import { Dismiss16 } from 'shared/components/BundledIcons';

const useClasses = makeStyles({
    button: {
        alignSelf: 'center',
    },
});

interface ToastProps {
    title: string;
    body: string;
    intent: ToastIntent;
    index: number;
}

export const useToast = () => {
    const toasterId = useId('toaster');
    const { dispatchToast } = useToastController(toasterId);
    const classes = useClasses();

    const toast = ({ body, intent = 'success' }: ToastProps) => {
        if (intent === 'error') {
            dispatchToast(
                <Toast>
                    <ToastTitle
                        action={
                            <ToastTrigger>
                                <Dismiss16 aria-label="dismiss message" color="black" className={classes.button} />
                            </ToastTrigger>
                        }
                    >
                        {body.slice(0, 1000) + (body.length > 1000 ? '...' : '')}
                    </ToastTitle>
                </Toast>,
                {
                    timeout: 10000,
                    intent,
                },
            );
        } else {
            dispatchToast(
                <Toast>
                    <ToastTitle>{body.slice(0, 1000) + (body.length > 1000 ? '...' : '')}</ToastTitle>
                </Toast>,
                {
                    timeout: 3000,
                    intent,
                },
            );
        }
    };

    return { toast, toasterId };
};
