import {
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { Tab, TabList } from '@fluentui/react-tabs';
import { FC, useMemo, useState } from 'react';
import { useServiceInfo } from 'modules/core/hooks/useServiceInfo';
import { useModules } from 'shared/hooks/useModules';

interface INavigationTabsProps {
    setChatState: () => void;
    setCollectionState: () => void;
    setPersonaState: () => void;
    setAdminSettingsState: () => void;
    setSkillState: (skill: JSX.Element) => void;
}

export const useClasses = makeStyles({
    rolesLabel: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalMNudge),
    },
    popoverMenu: {
        marginTop: '10px',
    },
    popover: {
        marginTop: '16px!important',
    },
    tabText: {
        color: '#fff',
        padding: '0px',
    },
    tabListCustom: {
        gap: tokens.spacingHorizontalL,
    },
    menuTab: {
        height: '35px',
    },
});

export const NavigationTabs: FC<INavigationTabsProps> = ({
    setChatState,
    setCollectionState,
    setPersonaState,
    setAdminSettingsState,
    setSkillState,
}) => {
    const [tab, setTab] = useState('chats');

    const { skills } = useModules();
    const { isAdmin, isAnyRoles } = useServiceInfo();

    const classes = useClasses();

    const skillTabs = useMemo(() => {
        const handleOnClick = (skill: JSX.Element) => {
            setSkillState(skill);
            setTab('skills');
        };

        return (
            <MenuPopover>
                <MenuList>
                    {skills
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((skill) => (
                            <MenuItem
                                key={skill.name}
                                onClick={() => {
                                    handleOnClick(skill.component);
                                }}
                            >
                                {skill.title}
                            </MenuItem>
                        ))}
                </MenuList>
            </MenuPopover>
        );
    }, [setSkillState, skills]);

    return (
        <TabList
            selectedValue={tab}
            size="small"
            className={classes.tabListCustom}
            onTabSelect={(_e, tab) => {
                const val = tab.value as string;

                if (val === 'skills') {
                    return;
                }

                setTab(val);
            }}
        >
            <Tab
                value="chats"
                data-testid="chatsButton"
                className={classes.menuTab}
                onClick={() => {
                    setChatState();
                }}
            >
                <p className={classes.tabText}>Chats</p>
            </Tab>
            {isAnyRoles && (
                <Tab
                    value="collections"
                    data-testid="collectionsButton"
                    className={classes.menuTab}
                    onClick={() => {
                        setCollectionState();
                    }}
                >
                    <p className={classes.tabText}>Collections</p>
                </Tab>
            )}
            {isAdmin && (
                <Tab
                    value="personas"
                    data-testid="personasButton"
                    className={classes.menuTab}
                    onClick={() => {
                        setPersonaState();
                    }}
                >
                    <p className={classes.tabText}>Personas</p>
                </Tab>
            )}

            {skills.length > 0 && isAnyRoles && (
                <Menu>
                    <MenuTrigger>
                        <Tab value="skills" data-testid="skillsButton" className={classes.menuTab}>
                            <p className={classes.tabText}>Skills</p>
                        </Tab>
                    </MenuTrigger>
                    {skillTabs}
                </Menu>
            )}

            {isAdmin && (
                <Tab
                    value="adminSettings"
                    data-testid="adminSettingsButton"
                    className={classes.menuTab}
                    onClick={() => {
                        setAdminSettingsState();
                    }}
                >
                    <p className={classes.tabText}>Admin</p>
                </Tab>
            )}
        </TabList>
    );
};
