import { EventType, PublicClientApplication, type AuthenticationResult, type EventMessage } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'shared/redux/app/store';
import { setAuthConfig } from 'shared/redux/features/app/appSlice';
import { BackendServiceUrl } from 'shared/services/BaseService';
import { AuthConfig, AuthHelper } from 'shared/utils/AuthUtils';

interface AppProviderProps {
    children: React.ReactNode;
}

export const Provider: React.FC<AppProviderProps> = ({ children }) => {
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication | undefined>(undefined);

    useEffect(() => {
        const fetchAuthConfig = async () => {
            try {
                const response = await fetch(new URL('api/Service/AuthConfig', BackendServiceUrl));
                if (!response.ok) throw new Error('No config loaded.');

                const authConfig = (await response.json()) as AuthConfig;
                store.dispatch(setAuthConfig(authConfig));

                if (AuthHelper.isAuthEnabled()) {
                    const instance = new PublicClientApplication(AuthHelper.getMsalConfig(authConfig));
                    const accounts = instance.getAllAccounts();

                    if (accounts.length > 0) {
                        instance.setActiveAccount(accounts[0]);
                    }

                    instance.addEventCallback((event: EventMessage) => {
                        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                            const payload = event.payload as AuthenticationResult;
                            const account = payload.account;
                            instance.setActiveAccount(account);
                        }
                    });

                    setMsalInstance(instance);
                }
            } catch (error) {
                console.error('Failed to load authentication configuration:', error);
                store.dispatch(setAuthConfig(undefined));
            }
        };

        void fetchAuthConfig();
    }, []);

    return (
        <ReduxProvider store={store}>
            {msalInstance ? <MsalProvider instance={msalInstance}>{children}</MsalProvider> : children}
        </ReduxProvider>
    );
};
