import { useMsal } from '@azure/msal-react';
import { Checkbox, Text } from '@fluentui/react';
import { Button, Title3, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useState } from 'react';
import { AcceptableUseText } from 'layout/components/AcceptableUseText';
import { getErrorDetails } from 'shared/utils/TextUtils';

const useClasses = makeStyles({
    acceptanceView: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '600px',
        margin: 'auto auto',
        height: '70%',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalNone),
    },
    scrollText: {
        ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalNone),
        flex: 1,
        overflowY: 'auto',
    },
    acceptCheckbox: {
        ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalNone),
    },
    actionsContainer: {
        height: 'auto',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
});

export const Login: React.FC = () => {
    const { instance } = useMsal();
    const classes = useClasses();

    const [acceptUseOfAI, setAcceptUseOfAI] = useState<boolean>(false);

    const onAcceptUseOfAIChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setAcceptUseOfAI(!!checked);
    };

    return (
        <div className={classes.acceptanceView}>
            <Title3 align="center">Use of service acknowledgement</Title3>
            <Text className={classes.scrollText}>
                <AcceptableUseText></AcceptableUseText>
            </Text>

            <div className={classes.actionsContainer}>
                <Checkbox
                    className={classes.acceptCheckbox}
                    checked={acceptUseOfAI}
                    label="I acknowledge that I have read and agree to the terms and conditions regarding the responsible use of AI for this web application."
                    onChange={onAcceptUseOfAIChange}
                />

                <div className={classes.buttonContainer}>
                    <Button
                        disabled={!acceptUseOfAI}
                        appearance="primary"
                        onClick={() => {
                            instance.loginRedirect().catch((e: unknown) => {
                                alert(`Error signing in: ${getErrorDetails(e)}`);
                            });
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};
