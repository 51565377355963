import {
    makeStyles,
    // shorthands
} from '@fluentui/react-components';
import { FC } from 'react';
import type { ICollectionState } from 'modules/core/redux/collections/CollectionState';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { CollectionList } from './collection-list/CollectionList';
import { CollectionWindow } from './CollectionWindow';

const useClasses = makeStyles({
    container: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const CollectionsView: FC = () => {
    const classes = useClasses();

    const { collections, selectedId } = useAppSelector((state: RootState) => state.collections);
    const selectedCollection = collections[selectedId] as ICollectionState | undefined;

    return (
        <div className={classes.container}>
            <CollectionList />
            {selectedCollection != null && <CollectionWindow />}
        </div>
    );
};
