import { PersonaHeader } from 'shared/libs/generated';

export interface IPersonasState {
    personas: Personas;
    selectedViewPersonaId: string | undefined;
}

export const initialState: IPersonasState = {
    personas: {},
    selectedViewPersonaId: undefined,
};

export type Personas = Record<string, PersonaHeader>;
