import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Settings24Regular } from '@fluentui/react-icons';
import React from 'react';
import { ChatHistory } from 'modules/core/components/chat/chat-history/ChatHistory';
import { GetResponseOptions, useChat } from 'modules/core/hooks/useChat';
import { Drawer } from 'shared/components/Drawer';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { SharedStyles } from 'shared/styles';
import { ChatInput } from './ChatInput';
import { DocumentsDrawer } from './drawer/DocumentsDrawer';
import { PersonaDrawer } from './drawer/PersonaDrawer';

const useClasses = makeStyles({
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    scroll: {
        ...SharedStyles.scroll,
    },
    history: {
        ...shorthands.padding(tokens.spacingVerticalM),
        paddingLeft: tokens.spacingHorizontalM,
        paddingRight: tokens.spacingHorizontalM,
        display: 'flex',
        justifyContent: 'center',
        marginTop: tokens.spacingVerticalXXL,
    },
    input: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalNone),
    },
    chatWindow: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
});

interface ChatRoomProps {
    openDrawer: boolean;
    setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
}

export const ChatRoom: React.FC<ChatRoomProps> = ({ openDrawer, setOpenDrawer }) => {
    const classes = useClasses();
    const chat = useChat();

    const { chats, selectedId } = useAppSelector((state: RootState) => state.chat);
    const messages = chats[selectedId].messages;

    const scrollViewTargetRef = React.useRef<HTMLDivElement>(null);
    const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

    React.useEffect(() => {
        if (!shouldAutoScroll) {
            return;
        }

        scrollViewTargetRef.current?.scrollTo(0, scrollViewTargetRef.current.scrollHeight);
    }, [messages, shouldAutoScroll]);

    React.useEffect(() => {
        const onScroll = () => {
            if (!scrollViewTargetRef.current) {
                return;
            }

            const { scrollTop, scrollHeight, clientHeight } = scrollViewTargetRef.current;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
            setShouldAutoScroll(isAtBottom);
        };

        if (!scrollViewTargetRef.current) {
            return;
        }

        const currentScrollViewTarget = scrollViewTargetRef.current;

        currentScrollViewTarget.addEventListener('scroll', onScroll);

        return () => {
            currentScrollViewTarget.removeEventListener('scroll', onScroll);
        };
    }, []);

    const handleSubmit = async (options: GetResponseOptions) => {
        await chat.getResponse(options);
        setShouldAutoScroll(true);
    };

    return (
        <div className={classes.chatWindow}>
            <div className={classes.root}>
                <div ref={scrollViewTargetRef} className={classes.scroll}>
                    <div className={classes.history}>
                        <ChatHistory messages={messages} onSubmit={handleSubmit} />
                    </div>
                </div>

                <div className={classes.input}>
                    <ChatInput onSubmit={handleSubmit} />
                </div>
            </div>

            <Drawer
                header={{ icon: <Settings24Regular />, text: 'Settings' }}
                body={
                    <>
                        <DocumentsDrawer />
                        <PersonaDrawer />
                    </>
                }
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                }}
            />
        </div>
    );
};
