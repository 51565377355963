import { Button, makeStyles, Tooltip } from '@fluentui/react-components';
import { useMemo, useState } from 'react';
import { Breakpoints, BreakpointThresholds } from 'shared/styles';

interface Props {
    children?: JSX.Element | JSX.Element[];
    icon?: JSX.Element;
    tooltipText: string;
    onClick: () => void;
    position: 'top-right' | 'top-left';
}

const useClasses = makeStyles({
    button: {
        ...Breakpoints.small({
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1000,
        }),
    },
});

export const MobileSafeButton: React.FC<Props> = ({ children, icon, tooltipText, position, onClick }) => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const classes = useClasses();

    window.addEventListener('resize', () => {
        setWindowWidth(window.innerWidth);
    });

    const isMobile = useMemo(() => {
        return windowWidth < BreakpointThresholds.small;
    }, [windowWidth]);

    const style = useMemo(() => {
        if (isMobile && position === 'top-right') {
            return {
                top: '10px',
                right: '10px',
            };
        }

        if (isMobile && position === 'top-left') {
            return {
                top: '10px',
                left: '10px',
            };
        }

        return {};
    }, [isMobile, position]);

    return (
        <Tooltip content={tooltipText} relationship="label">
            <Button
                appearance={isMobile ? 'secondary' : 'transparent'}
                icon={icon}
                onClick={onClick}
                className={classes.button}
                style={style}
            >
                {!isMobile && <>{children}</>}
            </Button>
        </Tooltip>
    );
};
