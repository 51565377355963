import { makeStyles, Label, Select, Button, Tooltip, Text } from '@fluentui/react-components';
import { Info24Regular } from '@fluentui/react-icons';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useChat } from 'modules/core/hooks/useChat';
import { editChatSettings } from 'modules/core/redux/chats/chatsSlice';
import { PersonaStatus } from 'shared/libs/generated';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import 'shared/assets/styles/tree-select.css';

const useClasses = makeStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoButton: {
        alignItems: 'flex-end',
    },
    description: {
        display: 'block',
        marginTop: '10px',
    },
});

export const PersonaDrawer: React.FC = () => {
    const classes = useClasses();
    const dispatch = useDispatch();
    const chat = useChat();

    const { personas } = useAppSelector((state: RootState) => state.personas);
    const { chats, selectedId } = useAppSelector((state: RootState) => state.chat);

    const persona = useMemo(() => {
        return personas[chats[selectedId].settings.personaId];
    }, [chats, personas, selectedId]);

    const allAvailablePersonas = useMemo(() => {
        return Object.values(personas).filter(
            (persona) => persona.status === PersonaStatus.ACTIVE || persona.id === chats[selectedId].settings.personaId,
        );
    }, [chats, personas, selectedId]);

    const handlePersonaSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPersonaId = e.target.value;
        void chat.editPersonaSetting(newPersonaId, selectedId);
        dispatch(
            editChatSettings({ id: selectedId, settings: { ...chats[selectedId].settings, personaId: newPersonaId } }),
        );
    };

    return (
        <div role="tabpanel" aria-labelledby="Persona">
            <div className={classes.header}>
                <Label weight="semibold">Personas</Label>
            </div>

            <div>
                <div className={classes.header}>
                    <Label>Chat Persona</Label>
                    <Tooltip content="Select a persona to modify how the chat responds" relationship="label">
                        <Button
                            className={classes.infoButton}
                            icon={<Info24Regular />}
                            appearance="transparent"
                            aria-label="Info: This persona will modify how the chat responds"
                        />
                    </Tooltip>
                </div>

                <Select value={persona.id} onChange={handlePersonaSelection}>
                    {allAvailablePersonas.map((persona) => (
                        <option key={persona.id} value={persona.id}>
                            {persona.name}
                        </option>
                    ))}
                </Select>
            </div>

            <div>
                <span className={classes.description}>
                    <Label weight="regular">Description</Label>{' '}
                    <Tooltip content="A short description of the selected persona" relationship="label">
                        <Button
                            className={classes.infoButton}
                            icon={<Info24Regular />}
                            appearance="transparent"
                            aria-label="Info: A short description of the selected persona"
                        />
                    </Tooltip>
                </span>
                <Text>{persona.description}</Text>
            </div>
        </div>
    );
};
