import {
    makeStyles,
    tokens,
    Label,
    Input,
    Textarea,
    Text,
    Button,
    shorthands,
    Tooltip,
    Checkbox,
    CheckboxOnChangeData,
    Badge,
} from '@fluentui/react-components';
import { Info16Regular, Save20Regular } from '@fluentui/react-icons';
import React, { useEffect, useRef, useState } from 'react';
import DropdownTreeSelect, { type TreeNode } from 'react-dropdown-tree-select';
import { usePersona } from 'modules/core/hooks/usePersona';
import { setSelectedViewPersonaById } from 'modules/core/redux/personas/personasSlice';
import { useTree } from 'shared/hooks/useTree';
import { PersonaHeader, PersonaStatus } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { Breakpoints } from 'shared/styles';

const useClasses = makeStyles({
    personasWindow: {
        width: '100%',
        height: 'calc(100% - 40px)', // Temporary until alerts are positioned absolutely
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'var(--colorNeutralBackground4)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem',
        paddingTop: '40px',
    },
    personasContent: {
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        ...Breakpoints.large({
            width: '90%',
        }),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        ...shorthands.margin(tokens.spacingVerticalXL, tokens.spacingHorizontalNone),
    },
    dialogTitle: {
        fontSize: '24px',
        fontWeight: 600,
        height: '24px',
    },
    alerts: {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        maxWidth: '400px',
        gap: '10px',
    },
    personasForm: {
        flex: 3,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    headingWithActions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: '10px',
    },
    labelRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelInline: {
        display: 'flex',
    },
    personaText: {
        marginLeft: '10px',
    },
    redText: {
        color: 'red',
    },
    supportingLabelText: {
        fontSize: '12px',
        color: '#665',
    },
    formLabel: {
        margin: 'auto 0',
    },
    default: {
        verticalAlign: 'middle',
        color: '#333',
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
});

export const PersonaWindow: React.FC = () => {
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
    const [formData, setFormData] = useState<PersonaHeader>({
        id: '',
        name: '',
        description: '',
        message: '',
        isDefault: false,
        requiresDocuments: false,
        status: PersonaStatus.ACTIVE,
        createdTimestamp: '',
        modifiedTimestamp: '',
        documentIds: [],
        collectionIds: [],
    });

    const classes = useClasses();

    const { publicCollections, settings } = useTree({ selectedDocumentNodes: selectedDocuments });
    const { personas, selectedViewPersonaId } = useAppSelector((state: RootState) => state.personas);
    const { collections } = useAppSelector((state: RootState) => state.collections);

    const dispatch = useAppDispatch();
    const persona = usePersona();

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (selectedViewPersonaId !== undefined) {
            setFormData(personas[selectedViewPersonaId]);
            return;
        }

        const activePersonas = Object.values(personas).filter((p) => p.status === PersonaStatus.ACTIVE);
        if (!activePersonas.length) {
            return;
        }

        const persona = activePersonas[activePersonas.length - 1];
        dispatch(setSelectedViewPersonaById(persona.id));
        setFormData(persona);
        setSelectedDocuments(persona.documentIds);
    }, [dispatch, personas, selectedViewPersonaId]);

    useEffect(() => {
        if (!selectedViewPersonaId) {
            return;
        }

        const selectedPersona = personas[selectedViewPersonaId];

        const collectionIds = selectedPersona.collectionIds.map((collectionId) => `collection..${collectionId}`);

        const documentIds = selectedPersona.documentIds.map((documentId) => {
            let collectionId = '';
            Object.keys(collections).forEach((key) => {
                const matchingDocument = collections[key].documents.find((x) => x.id === documentId);
                if (matchingDocument) {
                    collectionId = matchingDocument.collectionId;
                }
            });
            return `document..${documentId}..${collectionId}`;
        });

        setSelectedDocuments([...collectionIds, ...documentIds]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedViewPersonaId]);

    useEffect(() => {
        resizeTextArea(textAreaRef.current);
    }, [formData]);

    useEffect(() => {
        setFormData({ ...formData, collectionIds: settings.collectionIds, documentIds: settings.documentIds });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    const resizeTextArea = (textArea: HTMLInputElement | HTMLTextAreaElement | null) => {
        if (!textArea) {
            return;
        }

        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight + 'px';
    };

    const handleInputEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxEditChange = (e: React.ChangeEvent<HTMLInputElement | CheckboxOnChangeData>) => {
        const { checked } = e.target;
        setFormData({
            ...formData,
            ['requiresDocuments']: checked ? true : false,
        });
    };

    const handleTextAreaInputEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        resizeTextArea(e.target);
        handleInputEditChange(e);
    };

    const savePersona = () => {
        if (!selectedViewPersonaId) {
            return;
        }

        if (formData.name.trim() === '') {
            const message = `Failed to save. Persona name must not be empty.`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
            return;
        }
        if (formData.description.trim() === '') {
            const message = `Failed to save. Persona description must not be empty.`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
            return;
        }
        if (formData.message.length < minMessageCharacters) {
            const message = `Failed to save. Persona message is not long enough. It must be at least ${minMessageCharacters} characters long. It is currently ${formData.message.length} characters long.`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
            return;
        }

        // TODO: When this fails, it shows the success message still. Need to handle promises properly.
        void persona.updatePersona(
            selectedViewPersonaId,
            formData.name,
            formData.description,
            formData.message,
            formData.requiresDocuments,
            formData.documentIds,
            formData.collectionIds,
        );

        const message = 'Persona successfully saved';

        dispatch(
            addAlert({
                type: AlertType.Success,
                message,
            }),
        );
    };

    const minMessageCharacters = 100;

    const CounterText = () => {
        return (
            <Text className={classes.redText}>
                {formData.message.length} / {minMessageCharacters} characters.{' '}
                {minMessageCharacters - formData.message.length} characters left.
            </Text>
        );
    };

    const changeDocuments = (_currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        setSelectedDocuments(selectedNodes.map((x) => x.value));
    };

    return (
        <div className={classes.personasWindow}>
            <div className={classes.personasContent}>
                <div className={classes.personasForm}>
                    <div className={classes.header}>
                        <div className={classes.headingWithActions}>
                            <Text className={classes.dialogTitle}>
                                {formData.name}{' '}
                                {formData.isDefault && (
                                    <Badge appearance="filled" className={classes.default}>
                                        Default
                                    </Badge>
                                )}
                            </Text>
                            <Button type="submit" onClick={savePersona} appearance="secondary" icon={<Save20Regular />}>
                                Save
                            </Button>
                        </div>
                        <Text className={classes.supportingLabelText}>
                            Personas are fully customizable representations of personalities used to control the AI
                            behaviours.
                        </Text>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.labelRow}>
                            <Label className={classes.formLabel} weight="semibold" required htmlFor={'name-input-edit'}>
                                Name
                            </Label>
                            <Tooltip
                                content={{
                                    children: 'A short name for the persona.',
                                }}
                                positioning="above-start"
                                withArrow
                                relationship="label"
                            >
                                <Info16Regular tabIndex={0} />
                            </Tooltip>
                        </div>
                        <Input
                            required
                            name="name"
                            type="text"
                            id={'name-input-edit'}
                            value={formData.name}
                            onChange={handleInputEditChange}
                        />
                        <div className={classes.labelRow}>
                            <Label
                                className={classes.formLabel}
                                weight="semibold"
                                required
                                htmlFor={'description-input-edit'}
                            >
                                Description
                            </Label>{' '}
                            <Tooltip
                                content={{
                                    children:
                                        'A short summary of the personas intent. This text does not change the personas behaviour.',
                                }}
                                positioning="above-start"
                                withArrow
                                relationship="label"
                            >
                                <Info16Regular tabIndex={0} />
                            </Tooltip>
                        </div>
                        <Input
                            required
                            name="description"
                            type="text"
                            value={formData.description}
                            onChange={handleInputEditChange}
                        />
                        <div className={classes.labelRow}>
                            <Label
                                className={classes.formLabel}
                                weight="semibold"
                                required
                                htmlFor={'persona-message-input-edit'}
                            >
                                Message
                            </Label>
                            <Tooltip
                                content={{
                                    children:
                                        'Ensure Persona messages are sufficiently detailed with the intended behaviour and rules.',
                                }}
                                positioning="above-start"
                                withArrow
                                relationship="label"
                            >
                                <Info16Regular tabIndex={0} />
                            </Tooltip>
                        </div>
                        <Textarea
                            ref={textAreaRef}
                            required
                            name="message"
                            resize="vertical"
                            rows={5}
                            id={'persona-message-input-edit'}
                            value={formData.message}
                            onChange={handleTextAreaInputEditChange}
                        />
                        {formData.message.length < minMessageCharacters && <CounterText />}

                        <div className={classes.labelRow}>
                            <Label className={classes.formLabel} weight="semibold">
                                Collections and Documents
                            </Label>
                            <Tooltip
                                content={{
                                    children:
                                        'Select the collections and documents that you want for this chat. Only the selected documents and collections will be used when asking questions.',
                                }}
                                positioning="above-start"
                                withArrow
                                relationship="label"
                            >
                                <Info16Regular tabIndex={0} />
                            </Tooltip>
                        </div>
                        <DropdownTreeSelect
                            texts={{ placeholder: 'Search documents...' }}
                            data={publicCollections}
                            onChange={changeDocuments}
                            keepOpenOnSelect={true}
                            className="document-selector"
                        />

                        <div className={classes.labelInline}>
                            <div className={classes.vertical}>
                                <div className={classes.labelInline}>
                                    <Label className={classes.formLabel} weight="semibold">
                                        Are documents needed for this persona to work correctly?
                                    </Label>
                                    <Checkbox
                                        checked={formData.requiresDocuments}
                                        onChange={handleCheckboxEditChange}
                                    />
                                </div>
                                <Label className={classes.supportingLabelText}>
                                    If <strong>ticked</strong>, the persona will only stricly use the documents
                                    provided.
                                </Label>
                                <Label className={classes.supportingLabelText}>
                                    If <strong>unticked</strong>, the persona will attempt to answer the question in
                                    part with its own knowledge. Only tick this box if you intend to ask the persona
                                    questions that are not related to any specific documents.
                                </Label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
