import { importDocuments } from 'modules/core/redux/collections/collectionsSlice';
import { CollectionService } from 'modules/core/services/CollectionService';
import { UploadDocumentsResponse } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch } from 'shared/redux/app/hooks';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { useAuth } from './useAuth';

type FileInput =
    | { type: 'file'; file: File }
    | { type: 'fileList'; fileList: FileList }
    | { type: 'ref'; ref: React.MutableRefObject<HTMLInputElement | null> };

export const GetFiles = (input: FileInput): File[] => {
    switch (input.type) {
        case 'file':
            return [input.file];
        case 'fileList':
            return Array.from(input.fileList);
        case 'ref':
            return input.ref.current?.files ? Array.from(input.ref.current.files) : [];
    }
};

export const useFile = () => {
    const dispatch = useAppDispatch();
    const collectionService = new CollectionService();

    const { getToken } = useAuth();

    async function loadFile<T>(file: File, loadCallBack: (data: T) => Promise<void>): Promise<T> {
        return await new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async (event: ProgressEvent<FileReader>) => {
                const content = event.target?.result as string;
                try {
                    const parsedData = JSON.parse(content) as T;
                    await loadCallBack(parsedData);
                    resolve(parsedData);
                } catch (e) {
                    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                    reject(e);
                }
            };
            fileReader.onerror = reject;
            fileReader.readAsText(file);
        });
    }

    function downloadFile(filename: string, content: string, type: string) {
        const data: BlobPart[] = [content];
        let file: File | null = new File(data, filename, { type });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = filename;

        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
        file = null;
    }

    const handleImport = async (collectionId: string, input: FileInput): Promise<UploadDocumentsResponse | null> => {
        const filesArray = GetFiles(input);
        let response: UploadDocumentsResponse | null = null;

        if (filesArray.length > 0) {
            await collectionService
                .uploadDocumentsAsync(collectionId, filesArray, await getToken())
                .then((res) => {
                    dispatch(
                        importDocuments({
                            importingDocuments: filesArray.map((file) => file.name),
                            collectionId,
                        }),
                    );

                    response = res;
                })
                .catch((e: any) => {
                    const errorDetails = `Details: ${(e as Error).message}`;
                    dispatch(
                        addAlert({
                            message: `Unable to upload documents. ${errorDetails}`,
                            type: AlertType.Error,
                        }),
                    );
                });
        }

        if (input.type === 'ref' && input.ref.current) {
            input.ref.current.value = '';
        }

        return response;
    };

    const createDownloadableFile = (data: string, fileName: string, contentType: string) => {
        const blob = new Blob([data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const element = document.createElement('a');

        element.href = url;
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return {
        loadFile,
        downloadFile,
        handleImport,
        createDownloadableFile,
    };
};
