import { Personas } from 'modules/core/redux/personas/PersonasState';
import { PersonaHeader } from 'shared/libs/generated';

export function getDefaultPersona(personas: Personas): PersonaHeader {
    const defaultPersonas = Object.keys(personas)
        .filter((personaId) => personas[personaId].isDefault)
        .map((personaId) => personas[personaId]);

    if (!defaultPersonas.length) {
        throw new Error('No default personas.');
    }

    return defaultPersonas[0];
}
