import { AddSquare20Regular, Copy20Regular, Delete20Regular, Star20Regular } from '@fluentui/react-icons';
import { FC, useEffect, useMemo, useState } from 'react';
import { usePersona } from 'modules/core/hooks/usePersona';
import { setSelectedViewPersonaById } from 'modules/core/redux/personas/personasSlice';
import { Personas } from 'modules/core/redux/personas/PersonasState';
import { DeleteDialog } from 'shared/components/DeleteDialog';
import { CollapsableList } from 'shared/components/list/CollapsableList';
import { CollapsableListItem } from 'shared/components/list/CollapsableListItem';
import { PersonaStatus } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { getDefaultPersona } from 'shared/utils/PesonaUtils';

export const PersonaList: FC = () => {
    const { personas, selectedViewPersonaId } = useAppSelector((state: RootState) => state.personas);

    const [allPersonas, setAllPersonas] = useState<Personas>({});
    const [itemBeingDeleted, setItemBeingDeleted] = useState<string | undefined>();

    const persona = usePersona();
    const dispatch = useAppDispatch();

    const defaultPersona = useMemo(() => {
        return getDefaultPersona(personas);
    }, [personas]);

    const currentPersona = personas[selectedViewPersonaId ?? defaultPersona.id];

    useEffect(() => {
        const nonHiddenPersonas: Personas = {};

        for (const key in personas) {
            const persona = personas[key];

            if (persona.status !== PersonaStatus.REMOVED) {
                nonHiddenPersonas[key] = persona;
            }
        }

        setAllPersonas(nonHiddenPersonas);
    }, [personas]);

    const onAddClick = async () => {
        const newName = 'New Persona';
        const newDescription = 'Add a short description';
        const newMessage = defaultPersona.message;
        const newPersonaId = await persona.createPersona(newName, newDescription, newMessage, false);
        if (newPersonaId !== null) {
            dispatch(setSelectedViewPersonaById(newPersonaId));
        }
    };

    const onEditName = (personaId: string) => async (newName: string) => {
        await persona.updatePersona(
            personaId,
            newName,
            currentPersona.description,
            currentPersona.message,
            currentPersona.requiresDocuments,
            currentPersona.documentIds,
            currentPersona.collectionIds,
        );
    };

    const clonePersona = (personaId: string) => {
        const duplicatedName = `${currentPersona.name} (Copy)`;

        const selectedPersona = personas[personaId];

        void persona.createPersona(
            duplicatedName,
            selectedPersona.description,
            selectedPersona.message,
            selectedPersona.requiresDocuments,
        );

        const message = `Persona successfully duplicated with name ${duplicatedName}. Make sure to update the description and message before using it.`;

        dispatch(
            addAlert({
                type: AlertType.Success,
                message,
            }),
        );
    };

    const deletePersona = (personaId: string) => () => {
        const selectedPersona = personas[personaId];
        const deletedPersonaName = selectedPersona.name;

        if (selectedPersona.isDefault) {
            return;
        }

        void persona.deletePersona(personaId);

        const activePersonas = Object.values(personas).filter((p) => p.status === PersonaStatus.ACTIVE);
        if (activePersonas.length > 0) {
            const persona = activePersonas[activePersonas.length - 1];
            dispatch(setSelectedViewPersonaById(persona.id));
        }

        const message = `Persona successfully deleted. Existing chats with the ${deletedPersonaName} persona can still use it.`;

        dispatch(
            addAlert({
                type: AlertType.Success,
                message,
            }),
        );
    };

    const setDefaultPersona = (personaId: string) => {
        const selectedPersona = personas[personaId];

        void persona.setPersonaAsDefault(personaId);

        const message = `${selectedPersona.name} successfully set as the default persona.`;

        dispatch(
            addAlert({
                type: AlertType.Success,
                message,
            }),
        );
    };

    return (
        <>
            <CollapsableList
                title="Personas"
                items={Object.values(allPersonas)}
                renderItem={(persona) => (
                    <CollapsableListItem
                        key={persona.id}
                        id={persona.id}
                        header={persona.name}
                        preview={persona.description}
                        isSelected={persona.id === selectedViewPersonaId}
                        onClick={() => {
                            dispatch(setSelectedViewPersonaById(persona.id));
                        }}
                        onEditName={(newName) => {
                            void onEditName(persona.id)(newName);
                        }}
                        extraActions={[
                            {
                                icon: <Star20Regular />,
                                label: 'Set Default Persona',
                                disabled: persona.isDefault,
                                hidden: persona.isDefault,
                                onClick: () => {
                                    setDefaultPersona(persona.id);
                                },
                            },
                            {
                                icon: <Copy20Regular />,
                                label: 'Clone Persona',
                                onClick: () => {
                                    clonePersona(persona.id);
                                },
                            },
                            {
                                icon: <Delete20Regular />,
                                label: 'Delete Persona',
                                disabled: persona.isDefault,
                                hidden: persona.isDefault,
                                onClick: () => {
                                    setItemBeingDeleted(persona.id);
                                },
                            },
                        ]}
                    />
                )}
                sections={[
                    {
                        header: 'Default Persona',
                        predicate: (persona) => persona.isDefault,
                    },
                    {
                        header: 'Custom Personas',
                        predicate: (persona) => !persona.isDefault,
                    },
                ]}
                filterCriteria={(persona, searchText) => persona.name.toLowerCase().includes(searchText.toLowerCase())}
                addItem={{
                    action: () => {
                        void onAddClick();
                    },
                    type: 'button',
                    icon: <AddSquare20Regular />,
                    label: 'Add new persona',
                }}
            />

            <DeleteDialog
                subject={currentPersona.name}
                description={'This action will remove the persona.'}
                onConfirm={deletePersona(itemBeingDeleted ?? '')}
                open={!!itemBeingDeleted}
                onClose={() => {
                    setItemBeingDeleted(undefined);
                }}
            />
        </>
    );
};
