import { useMsal } from '@azure/msal-react';
import { Title3, Spinner, Body1 } from '@fluentui/react-components';
import { FC, useEffect, useMemo, useState } from 'react';
import { IMaintenanceStatus, ServiceInfoService } from 'modules/core/services/ServiceInfoService';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { useSharedClasses } from 'shared/styles';

export const BackendProbe: FC = () => {
    const [_attempt, setAttempt] = useState(1);
    const [failed, setFailed] = useState(false);
    const [model, _setModel] = useState<IMaintenanceStatus | null>(null);

    const classes = useSharedClasses();
    const dispatch = useAppDispatch();
    const maintenanceService = useMemo(() => new ServiceInfoService(), []);

    const { isMaintenance } = useAppSelector((state: RootState) => state.app);
    const { instance, inProgress } = useMsal();

    useEffect(() => {
        const timer = setInterval(() => {
            setAttempt((prevAttempt) => {
                if (prevAttempt >= 50) {
                    // Clear the interval and set failed state to true
                    clearInterval(timer);
                    setFailed(true);
                    return prevAttempt;
                }
                return prevAttempt + 1;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [dispatch, maintenanceService, instance, inProgress]);

    if (failed) {
        return (
            <div className={classes.informativeView}>
                <Title3>Failed to connect to backend. Please contact support.</Title3>
            </div>
        );
    }

    if (isMaintenance) {
        return (
            <div className={classes.informativeView}>
                <Title3>{model?.title ?? 'Site undergoing maintenance...'}</Title3>
                <Spinner />
                <Body1>
                    {model?.message ?? 'Planned site maintenance is underway. We apologise for the disruption.'}
                </Body1>
                <Body1>
                    <strong>
                        {model?.note ??
                            "Note: If this message doesn't resolve after a significant duration, refresh the browser."}
                    </strong>
                </Body1>
            </div>
        );
    }

    return <div className={classes.informativeView} />;
};
