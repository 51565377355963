import { makeStyles, tokens } from '@fluentui/react-components';
import { Settings20Regular } from '@fluentui/react-icons';
import React, { useMemo } from 'react';
import { MobileSafeButton } from 'shared/components/MobileSafeButton';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { ChatRoom } from './ChatRoom';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground4,
        boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem',
    },
    buttonGroup: {
        padding: '0.5rem',
        position: 'absolute',
        right: '0',
    },
    iconWrapper: {
        position: 'relative',
        display: 'inline-block',
    },
    notificationBadge: {
        position: 'absolute',
        top: '-4px',
        right: '-4px',
        width: '10px',
        height: '10px',
        backgroundColor: 'red',
        borderRadius: '50%',
        border: `2px solid ${tokens.colorNeutralBackground4}`,
    },
});

export const ChatWindow: React.FC = () => {
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

    const classes = useClasses();
    const chat = useAppSelector((state: RootState) => state.chat.chats[state.chat.selectedId]);
    const persona = useAppSelector((state: RootState) => state.personas.personas[chat.settings.personaId]);

    const isNoDocumentsSelected = useMemo(() => {
        const documentsAndCollectionsFromPersona = persona.documentIds.length + persona.collectionIds.length;
        const documentsAndCollectionsFromChat = chat.settings.collectionIds.length + chat.settings.documentIds.length;
        return (
            persona.requiresDocuments &&
            documentsAndCollectionsFromPersona === 0 &&
            documentsAndCollectionsFromChat === 0
        );
    }, [
        persona.documentIds.length,
        persona.collectionIds.length,
        persona.requiresDocuments,
        chat.settings.collectionIds.length,
        chat.settings.documentIds.length,
    ]);

    const toggleDrawer = () => {
        setOpenDrawer((prev) => !prev);
    };

    return (
        <div className={classes.root}>
            <div className={classes.buttonGroup}>
                {!openDrawer && (
                    <MobileSafeButton
                        icon={
                            <div className={classes.iconWrapper}>
                                <Settings20Regular />
                                {isNoDocumentsSelected && <span className={classes.notificationBadge} />}
                            </div>
                        }
                        tooltipText="Settings"
                        onClick={() => {
                            toggleDrawer();
                        }}
                        position="top-right"
                    >
                        <span>{`Settings`}</span>
                    </MobileSafeButton>
                )}
            </div>

            <ChatRoom openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        </div>
    );
};
