import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'shared/redux/app/hooks';
import { AuthHelper } from 'shared/utils/AuthUtils';

export const useAuth = () => {
    const [token, setToken] = useState<string | null>(null);

    const { instance, inProgress } = useMsal();
    const { authConfig } = useAppSelector((state) => state.app);

    const isAuthenticated = useIsAuthenticated();
    const isAuthEnabled = useMemo(() => AuthHelper.isAuthEnabled(authConfig), [authConfig]);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const fetchedToken = await AuthHelper.getBackendAccessToken(instance, inProgress);
                setToken(fetchedToken);
            } catch (e) {
                setToken(null);
            }
        };

        void fetchToken();
    }, [instance, inProgress]);

    const getToken = useCallback(async () => {
        if (token == null) {
            return await AuthHelper.getBackendAccessToken(instance, inProgress);
        }

        return token;
    }, [token, instance, inProgress]);

    return {
        token,
        getToken,
        isAuthEnabled,
        isAuthenticated,
    };
};
