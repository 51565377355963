import { Action, Dispatch, Middleware } from '@reduxjs/toolkit';
import { ChatMessageHeader } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { RootState, StoreMiddlewareAPI } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { getOrCreateHubConnection } from './signalRHubConnection';

// The action sent to the SignalR middleware.
interface SignalRAction extends Action {
    payload: {
        message?: ChatMessageHeader;
        userId?: string;
        id?: string;
    };
}

export const signalRMiddleware: Middleware<any, RootState, Dispatch<SignalRAction>> = (store: StoreMiddlewareAPI) => {
    return (next) => (action) => {
        // Call the next dispatch method in the middleware chain before performing any async logic
        const signalRAction = action as SignalRAction;
        const result = next(signalRAction);

        // Get the SignalR connection instance
        const hubConnection = getOrCreateHubConnection(store);

        // The following actions will be captured by the SignalR middleware and broadcasted to all clients.
        switch (signalRAction.type) {
            case 'chats/setChats':
                Promise.all(
                    Object.keys(signalRAction.payload).map(async (id) => {
                        await hubConnection.invoke('AddClientToGroupAsync', id);
                    }),
                ).catch((err) => store.dispatch(addAlert({ message: String(err), type: AlertType.Error })));
                break;

            case 'chats/addChat':
                hubConnection
                    .invoke('AddClientToGroupAsync', signalRAction.payload.id)
                    .catch((err) => store.dispatch(addAlert({ message: String(err), type: AlertType.Error })));
                break;

            case 'collections/setCollections':
                Promise.all(
                    Object.keys(signalRAction.payload).map(async (id) => {
                        await hubConnection.invoke('AddClientToGroupAsync', id);
                    }),
                ).catch((err) => store.dispatch(addAlert({ message: String(err), type: AlertType.Error })));
                break;

            case 'collections/addCollection':
                hubConnection
                    .invoke('AddClientToGroupAsync', signalRAction.payload.id)
                    .catch((err) => store.dispatch(addAlert({ message: String(err), type: AlertType.Error })));
                break;
        }

        return result;
    };
};
