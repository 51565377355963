import { Card, Text } from '@fluentui/react-components';
import { FC } from 'react';
import { FeatureFlagHeader } from 'shared/libs/generated';
import { useAdminClasses } from './AdminSettingsView';

type Feature = keyof FeatureFlagHeader;

interface Props {
    featureFlags: Record<Feature, boolean>;
    setFeatureFlags: (featureFlags: Record<Feature, boolean>) => void;
}

const toTitleCase = (str: Feature) => {
    switch (str) {
        case 'rfx':
            return 'RFX';
        default:
            return str;
    }
};

export const AdminSettingsFeatureFlagsTab: FC<Props> = ({ featureFlags, setFeatureFlags }) => {
    const classes = useAdminClasses();

    const handleToggleFeatureFlag = (key: keyof FeatureFlagHeader) => {
        setFeatureFlags({ ...featureFlags, [key]: !featureFlags[key] });
    };

    return (
        <Card className={classes.content}>
            <Text className={classes.subtitle}>Enabled Features</Text>
            {Object.entries(featureFlags).map(([key, value]) => (
                <div key={key} className={classes.checkAndText}>
                    <Text>{toTitleCase(key as Feature)}</Text>
                    <input
                        type="checkbox"
                        checked={value}
                        onChange={() => {
                            handleToggleFeatureFlag(key as Feature);
                        }}
                    />
                </div>
            ))}
        </Card>
    );
};
