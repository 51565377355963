import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import App from 'layout/App';
import { Provider } from 'layout/Provider';

const container = document.getElementById('root');
if (!container) {
    throw new Error('Could not find root element');
}

const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
        <Provider>
            <App />
        </Provider>
    </React.StrictMode>,
);
