import {
    CollectionsApiService,
    CreateCollectionRequest,
    CreateCollectionResponse,
    RetrieveBlobKeyForContentRequest,
    RetrieveBlobKeyForUploadRequest,
    RetrieveCollectionResponse,
    RetrieveCollectionsResponse,
    RetrieveDocumentsByCollectionIdResponse,
    UpdateCollectionRequest,
    UploadDocumentsRequest,
    UploadDocumentsResponse,
} from 'shared/libs/generated';
import { BaseService } from 'shared/services/BaseService';

export class CollectionService extends BaseService {
    public createCollectionAsync = async (
        name: string,
        isPrivate: boolean,
        accessToken: string,
        role?: string,
    ): Promise<CreateCollectionResponse> => {
        this.setAccessToken(accessToken);

        const request: CreateCollectionRequest = {
            name,
            isPrivate,
            role,
        };

        try {
            return await CollectionsApiService.createCollectionEndpoint(request);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public updateCollectionAsync = async (collectionId: string, name: string, accessToken: string): Promise<void> => {
        this.setAccessToken(accessToken);

        const request: UpdateCollectionRequest = {
            name,
        };

        try {
            return CollectionsApiService.updateCollectionEndpoint(collectionId, request);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public deleteCollectionAsync = async (collectionId: string, accessToken: string): Promise<void> => {
        this.setAccessToken(accessToken);

        try {
            return CollectionsApiService.deleteCollectionEndpoint(collectionId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveCollectionsAsync = async (accessToken: string): Promise<RetrieveCollectionsResponse> => {
        this.setAccessToken(accessToken);

        try {
            return await CollectionsApiService.retrieveCollectionsEndpoint();
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveDocumentsByCollectionIdAsync = async (
        collectionId: string,
        accessToken: string,
    ): Promise<RetrieveDocumentsByCollectionIdResponse> => {
        this.setAccessToken(accessToken);

        try {
            return await CollectionsApiService.retrieveDocumentsByCollectionIdEndpoint(collectionId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveCollectionAsync = async (
        collectionId: string,
        accessToken: string,
    ): Promise<RetrieveCollectionResponse> => {
        this.setAccessToken(accessToken);

        try {
            return await CollectionsApiService.retrieveCollectionEndpoint(collectionId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public uploadDocumentsAsync = async (
        collectionId: string,
        documents: File[],
        accessToken: string,
    ): Promise<UploadDocumentsResponse> => {
        this.setAccessToken(accessToken);

        const formData: UploadDocumentsRequest = {
            files: [],
        };

        for (const document of documents) {
            formData.files.push(document);
        }

        try {
            return await CollectionsApiService.uploadDocumentsEndpoint(collectionId, formData);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public deleteDocumentAsync = async (
        collectionId: string,
        documentId: string,
        accessToken: string,
    ): Promise<void> => {
        this.setAccessToken(accessToken);

        try {
            return CollectionsApiService.deleteDocumentEndpoint(collectionId, documentId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveBlobKeyForUploadAsync = async (
        filePath: string,
        documentId: string,
        accessToken: string,
    ): Promise<string> => {
        this.setAccessToken(accessToken);

        const request: RetrieveBlobKeyForUploadRequest = {
            filePath,
            documentId,
        };

        try {
            const response = await CollectionsApiService.retrieveBlobKeyForUploadEndpoint(request);
            return response.result;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public retrieveBlobKeyForContentAsync = async (fileName: string, accessToken: string): Promise<string> => {
        this.setAccessToken(accessToken);

        const request: RetrieveBlobKeyForContentRequest = {
            fileName,
        };

        try {
            const response = await CollectionsApiService.retrieveBlobKeyForContentEndpoint(request);
            return response.result;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };
}
