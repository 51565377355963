import { ProgressBar, Text } from '@fluentui/react-components';
import { DocumentStatus } from 'shared/libs/generated';

interface Props {
    status: DocumentStatus;
}

type StatusColor = 'error' | 'brand' | 'success' | 'warning' | undefined;

export const CollectionProgressBar = ({ status }: Props) => {
    const getProgress = (status: DocumentStatus): number => {
        const adjustedStatus = status.toLowerCase() as DocumentStatus;
        switch (adjustedStatus) {
            case DocumentStatus.ADDED:
                return 20;
            case DocumentStatus.UPLOADED:
                return 50;
            case DocumentStatus.INDEXING:
                return 75;
            case DocumentStatus.COMPLETED:
                return 100;
            case DocumentStatus.ERROR:
            case DocumentStatus.DELETING:
                return 75;
            case DocumentStatus.DELETED:
                return 100;
            default:
                return 20;
        }
    };

    const getClassName = (status: DocumentStatus): StatusColor => {
        const adjustedStatus = status.toLowerCase() as DocumentStatus;
        switch (adjustedStatus) {
            case DocumentStatus.ADDED:
            case DocumentStatus.UPLOADED:
            case DocumentStatus.INDEXING:
                return 'brand';
            case DocumentStatus.COMPLETED:
                return 'success';
            case DocumentStatus.ERROR:
            case DocumentStatus.DELETING:
            case DocumentStatus.DELETED:
                return 'error';
            default:
                return 'brand';
        }
    };

    return (
        <div>
            <Text>{status}</Text>
            <ProgressBar
                value={getProgress(status)}
                max={100}
                thickness="large"
                color={getClassName(status)}
                className="progressbar"
                shape="rounded"
                style={{
                    transition: 'width 0.5s ease-in-out',
                }}
            />
        </div>
    );
};
