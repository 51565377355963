import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const useDynamicSelector = <T>(selector: (state: any) => T): T => {
    return useSelector(selector);
};

export const useDynamicDispatch = () => useDispatch();
