import { Button, makeStyles, Label, Tooltip, tokens, Divider } from '@fluentui/react-components';
import { Info24Regular } from '@fluentui/react-icons';
import React, { useEffect, useMemo, useState } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import { useChat } from 'modules/core/hooks/useChat';
import { editChatSettings } from 'modules/core/redux/chats/chatsSlice';
import { FileDropZone } from 'shared/components/FileDropZone';
import { useTree } from 'shared/hooks/useTree';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { getErrorDetails } from 'shared/utils/TextUtils';
import 'shared/assets/styles/tree-select.css';

const useClasses = makeStyles({
    infoButton: {
        alignItems: 'flex-end',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    content: {
        marginBottom: '1rem',
    },
    danger: {
        color: 'red',
        fontStyle: 'italic',
        fontSize: tokens.fontSizeBase100,
    },
    divider: {
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalM,
    },
});

export const DocumentsDrawer: React.FC = () => {
    const dispatch = useAppDispatch();
    const chat = useChat();
    const classes = useClasses();

    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

    const { chats, selectedId } = useAppSelector((state: RootState) => state.chat);
    const { collections } = useAppSelector((state: RootState) => state.collections);
    const { personas } = useAppSelector((state: RootState) => state.personas);
    const { allCollections: data, settings } = useTree({ selectedDocumentNodes: selectedDocuments });

    const persona = useMemo(() => {
        return personas[chats[selectedId].settings.personaId];
    }, [chats, personas, selectedId]);

    const keys = useMemo(() => {
        return Object.keys(collections);
    }, [collections]);

    useEffect(() => {
        const selectedChat = chats[selectedId];

        const collectionIds = selectedChat.settings.collectionIds.map((collectionId) => `collection..${collectionId}`);

        const documentIds = selectedChat.settings.documentIds.map((documentId) => {
            let collectionId = '';
            keys.forEach((key) => {
                const matchingDocument = collections[key].documents.find((x) => x.id === documentId);
                if (matchingDocument) {
                    collectionId = matchingDocument.collectionId;
                }
            });
            return `document..${documentId}..${collectionId}`;
        });

        setSelectedDocuments([...collectionIds, ...documentIds]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    useEffect(() => {
        const onSave = async () => {
            await chat.editChat(selectedId, undefined, settings).then(() => {
                dispatch(editChatSettings({ id: selectedId, settings }));
            });
        };

        onSave()
            // TODO: To be replaced by new alerts see issue #109
            // .then(() => {
            //     dispatch(addAlert({ message: 'Chat settings updated successfully!', type: AlertType.Success }));
            // })
            .catch((e: any) => {
                const errorMessage = `Unable to save chat settings. Details: ${getErrorDetails(e)}`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDocuments]);

    const onChange = (_currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        const selectedDocumentNodesTemp = [...selectedNodes.map((x) => x.value)];
        setSelectedDocuments(selectedDocumentNodesTemp);
    };

    const showDocumentWarning = useMemo(() => {
        const documentsAndCollectionsFromPersona = persona.documentIds.concat(persona.collectionIds);
        return (
            persona.requiresDocuments &&
            selectedDocuments.length === 0 &&
            documentsAndCollectionsFromPersona.length === 0
        );
    }, [persona.collectionIds, persona.documentIds, persona.requiresDocuments, selectedDocuments.length]);

    const treeSelect = useMemo(() => {
        return (
            <div>
                <div className="collectionsHeader">
                    <Label weight="regular">Collections and Documents</Label>
                    <Tooltip
                        content="Select the collections and documents that you want for this chat. Only the selected documents and collections will be used when asking questions."
                        relationship="label"
                    >
                        <Button
                            className={classes.infoButton}
                            icon={<Info24Regular />}
                            appearance="transparent"
                            aria-label="Info: Select the collections and documents that you want for this chat. Only the selected documents and collections will be used when asking questions."
                        />
                    </Tooltip>
                </div>

                <DropdownTreeSelect
                    texts={{ placeholder: 'Search...' }}
                    data={data}
                    onChange={onChange}
                    keepOpenOnSelect={true}
                    className="document-selector"
                />
            </div>
        );
        // eslint-disable-next-line
    }, [classes.infoButton, data]);

    return (
        <div className={classes.content}>
            <div className={classes.header}>
                <Label weight="semibold">Documents</Label>
            </div>
            {treeSelect}
            {showDocumentWarning && (
                <Label className={classes.danger}>
                    * No documents have been selected, you must select at least one document to chat against.
                </Label>
            )}
            <Divider className={classes.divider} />
            <FileDropZone
                type={{
                    type: 'collection',
                    onCollectionUpload: (_collection) => {},
                }}
                acceptedFileTypes={['application/pdf', 'text/plain']}
                maxFileSize={10485760}
                backgroundColor="transparent"
                showUploadButton={false}
            />
            <Divider className={classes.divider} />
        </div>
    );
};
