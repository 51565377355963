import { Button, Textarea, Tooltip, makeStyles, tokens } from '@fluentui/react-components';
import { Send20Regular } from '@fluentui/react-icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GetResponseOptions } from 'modules/core/hooks/useChat';
import { editChatInput, updateBotResponseStatus } from 'modules/core/redux/chats/chatsSlice';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';

const useClasses = makeStyles({
    root: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        marginTop: tokens.spacingVerticalL,
    },
    typingIndicator: {
        maxHeight: '28px',
    },
    content: {
        width: '100%',
        marginBottom: tokens.spacingVerticalL,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    coupled: {
        width: '79%',
        position: 'relative',
    },
    textArea: {
        minHeight: '30px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    textAreaRoot: {
        width: '100%',
        borderRadius: tokens.borderRadiusXLarge,
        paddingLeft: '10px',
        minHeight: '30px',
        position: 'relative',
        verticalAlign: 'middle',
        textAlign: 'left',
    },
    input: {
        width: '100%',
        paddingRight: '80px',
        borderRadius: '100px',
    },
    button: {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)', // Vertically center the button
        borderRadius: tokens.borderRadiusXLarge,
    },
});

interface ChatInputProps {
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

export const ChatInput: React.FC<ChatInputProps> = ({ onSubmit }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();

    const [value, setValue] = useState('');

    const selectedChat = useAppSelector((state: RootState) => state.chat.chats[state.chat.selectedId]);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const isSubmitting = selectedChat.botResponseStatus === 'Generating response';

    useEffect(() => {
        textAreaRef.current?.focus();
    }, [selectedChat.id]);

    const handleSubmit = useCallback(
        (value: string) => {
            if (value.trim() === '' || value.trim().length < 5) {
                if (value.trim() === '') {
                    return;
                }

                const message = 'Input must be at least 5 characters long';
                dispatch(
                    addAlert({
                        type: AlertType.Error,
                        message,
                    }),
                );
                return;
            }

            setValue('');
            dispatch(editChatInput({ id: selectedChat.id, newInput: '' }));
            dispatch(updateBotResponseStatus({ chatId: selectedChat.id, status: 'Generating response' }));
            onSubmit({ value, chatId: selectedChat.id }).catch((error) => {
                const message = `Error submitting chat input: ${(error as Error).message}`;
                dispatch(
                    addAlert({
                        type: AlertType.Error,
                        message,
                    }),
                );
            });
        },
        [dispatch, onSubmit, selectedChat.id],
    );

    const buttonElements = useMemo(() => {
        const elements = [
            {
                icon: <Send20Regular />,
                onClick: () => {
                    handleSubmit(value);
                },
                disabled: isSubmitting,
                label: 'Submit message',
                shouldShow: true,
            },
        ];

        return elements.filter((button) => button.shouldShow);
    }, [handleSubmit, isSubmitting, value]);

    const buttons = useMemo(() => {
        const divider =
            buttonElements.length > 1 ? (
                <div
                    style={{
                        position: 'absolute',
                        right: '51px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        height: '24px',
                        width: '1px',
                        backgroundColor: tokens.colorNeutralStroke1Hover,
                    }}
                />
            ) : null;

        return (
            <div>
                {buttonElements.map((button, index) => (
                    <Tooltip key={index} content={button.label} relationship="label">
                        <Button
                            className={classes.button}
                            style={{ right: `${index * 40 + 10}px` }}
                            size="large"
                            appearance="transparent"
                            icon={button.icon}
                            onClick={button.onClick}
                            disabled={button.disabled}
                        />
                    </Tooltip>
                ))}
                {divider}
            </div>
        );
    }, [buttonElements, classes.button]);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.coupled}>
                    <Textarea
                        title="Chat input"
                        aria-label="Chat input field. Click enter to submit input."
                        ref={textAreaRef}
                        id="chat-input"
                        textarea={{
                            className: classes.textArea,
                            rows: 1,
                        }}
                        root={{
                            className: classes.textAreaRoot,
                            style: { paddingRight: buttonElements.length * 40 + 'px' },
                        }}
                        className={classes.input}
                        appearance="outline"
                        resize="none"
                        value={value}
                        onFocus={() => {
                            const chatInput = document.getElementById('chat-input');
                            if (chatInput) {
                                setValue((chatInput as HTMLTextAreaElement).value);
                            }
                        }}
                        onChange={(_event, data) => {
                            setValue(data.value);

                            const textArea = _event.target;
                            textArea.style.height = 'auto';
                            textArea.style.height = textArea.scrollHeight + 'px';
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                handleSubmit(value);
                            }
                        }}
                    />

                    {buttons}
                </div>
            </div>
        </div>
    );
};
