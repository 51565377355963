import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonaHeader } from 'shared/libs/generated';
import { initialState, Personas, IPersonasState } from './PersonasState';

export const personasSlice = createSlice({
    name: 'personas',
    initialState,
    reducers: {
        setPersonas: (state: IPersonasState, action: PayloadAction<Personas>) => {
            state.personas = action.payload;
        },
        addPersona: (state: IPersonasState, action: PayloadAction<PersonaHeader>) => {
            const newId = action.payload.id;
            state.personas = { [newId]: action.payload, ...state.personas };
        },
        setSelectedViewPersonaById: (state: IPersonasState, action: PayloadAction<string>) => {
            state.selectedViewPersonaId = action.payload;
        },
    },
});

export const { setPersonas, addPersona, setSelectedViewPersonaById } = personasSlice.actions;

export default personasSlice.reducer;
