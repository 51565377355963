import { FC } from 'react';
import { useAppSelector } from 'shared/redux/app/hooks';

export const AcceptableUseText: FC = () => {
    const { termsOfUse } = useAppSelector((state) => state.app.serviceInfo.settings);

    return (
        <>
            {termsOfUse.split('\n').map((line, index) => (
                <span key={index}>
                    {line}
                    <br />
                </span>
            ))}
        </>
    );
};
