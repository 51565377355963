import { setPersonas, addPersona } from 'modules/core/redux/personas/personasSlice';
import { Personas } from 'modules/core/redux/personas/PersonasState';
import { PersonaService } from 'modules/core/services/PersonaService';
import { useAuth } from 'shared/hooks/useAuth';
import { CreatePersonaResponse } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch } from 'shared/redux/app/hooks';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { getErrorDetails } from 'shared/utils/TextUtils';

export const usePersona = () => {
    const dispatch = useAppDispatch();
    const personaService = new PersonaService();

    const { getToken } = useAuth();

    const loadPersonas = async () => {
        try {
            const accessToken = await getToken();
            const personaResult = await personaService.retrievePersonasAsync(accessToken);
            const loadedPersonas: Personas = {};

            for (const persona of personaResult.results) {
                loadedPersonas[persona.id] = {
                    id: persona.id,
                    name: persona.name,
                    description: persona.description,
                    message: persona.message,
                    isDefault: persona.isDefault,
                    requiresDocuments: persona.requiresDocuments,
                    status: persona.status,
                    createdTimestamp: persona.createdTimestamp,
                    modifiedTimestamp: persona.modifiedTimestamp,
                    documentIds: persona.documentIds,
                    collectionIds: persona.collectionIds,
                };
            }
            dispatch(setPersonas(loadedPersonas));
        } catch (e: any) {
            const errorMessage = `Unable to load personas. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return false;
        }
        return true;
    };

    const createPersona = async (name: string, description: string, message: string, requiresDocuments: boolean) => {
        try {
            const newPersonaId = await personaService
                .createPersonaAsync(name, description, message, requiresDocuments, [], [], await getToken())
                .then((result: CreatePersonaResponse) => {
                    dispatch(addPersona(result));
                    return result.id;
                });
            return newPersonaId;
        } catch (e: any) {
            const errorMessage = `Unable to create persona. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return null;
        }
    };

    const deletePersona = async (personaId: string) => {
        try {
            await personaService.deletePersonaAsync(personaId, await getToken()).then(async () => {
                await loadPersonas();
            });
        } catch (e: any) {
            const errorMessage = `Unable to delete persona. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const updatePersona = async (
        personaId: string,
        name: string,
        description: string,
        message: string,
        requiresDocuments: boolean,
        documentIds: string[],
        collectionIds: string[],
    ) => {
        try {
            await personaService
                .updatePersonaAsync(
                    personaId,
                    name,
                    description,
                    message,
                    requiresDocuments,
                    documentIds,
                    collectionIds,
                    await getToken(),
                )
                .then(async () => {
                    await loadPersonas();
                });
        } catch (e: any) {
            const errorMessage = `Unable to update persona. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const setPersonaAsDefault = async (personaId: string) => {
        try {
            await personaService.setPersonaAsDefaultAsync(personaId, await getToken()).then(async () => {
                await loadPersonas();
            });
        } catch (e: any) {
            const errorMessage = `Unable to update persona. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    return {
        loadPersonas,
        createPersona,
        deletePersona,
        updatePersona,
        setPersonaAsDefault,
    };
};
