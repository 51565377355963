import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessageHeader } from 'shared/libs/generated';
import {
    IChatSessionState,
    IChatInputChange,
    Chats,
    IChatsState,
    IChatTitleChange,
    initialState,
    IChatSettingsChange,
} from './ChatState';

export const chatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        setChats: (state: IChatsState, action: PayloadAction<Chats>) => {
            state.chats = action.payload;
        },
        editChatTitle: (state: IChatsState, action: PayloadAction<IChatTitleChange>) => {
            const id = action.payload.id;
            const newTitle = action.payload.newTitle;
            state.chats[id].title = newTitle;
            frontLoadChat(state, id);
        },
        editChatInput: (state: IChatsState, action: PayloadAction<IChatInputChange>) => {
            const id = action.payload.id;
            const newInput = action.payload.newInput;
            state.chats[id].input = newInput;
        },
        editChatSettings: (state: IChatsState, action: PayloadAction<IChatSettingsChange>) => {
            const id = action.payload.id;
            const newSettings = action.payload.settings;
            state.chats[id].settings = newSettings;
            frontLoadChat(state, id);
        },
        setSelectedChat: (state: IChatsState, action: PayloadAction<string>) => {
            state.selectedId = action.payload;
        },
        addChat: (state: IChatsState, action: PayloadAction<IChatSessionState>) => {
            const newId = action.payload.id;
            state.chats = { [newId]: action.payload, ...state.chats };
            state.selectedId = newId;
        },
        /*
         * addMessageToChatFromUser() and addMessageToChatFromServer() both update the chats state.
         * However they are for different purposes. The former action is for updating the chat from the
         * webapp and will be captured by the SignalR middleware and the payload will be broadcasted to all clients
         * in the same group.
         * The addMessageToChatFromServer() action is triggered by the SignalR middleware when a response is received
         * from the webapi.
         */
        addMessageToChatFromUser: (
            state: IChatsState,
            action: PayloadAction<{ message: ChatMessageHeader; chatId: string }>,
        ) => {
            const { message, chatId } = action.payload;
            updateChat(state, chatId, message);
        },
        addMessageToChatFromServer: (
            state: IChatsState,
            action: PayloadAction<{ message: ChatMessageHeader; chatId: string }>,
        ) => {
            const { message, chatId } = action.payload;
            updateChat(state, chatId, message);
        },
        updateBotResponseStatus: (
            state: IChatsState,
            action: PayloadAction<{ chatId: string; status: string | undefined }>,
        ) => {
            const { chatId, status } = action.payload;
            const chat = state.chats[chatId];
            chat.botResponseStatus = status;
        },
        updateMessageProperty: <K extends keyof ChatMessageHeader, V extends ChatMessageHeader[K]>(
            state: IChatsState,
            action: PayloadAction<{
                property: K;
                value: V;
                chatId: string;
                messageIdOrIndex: string | number;
                updatedContent?: string;
                frontLoad?: boolean;
            }>,
        ) => {
            const { property, value, messageIdOrIndex, chatId, updatedContent, frontLoad } = action.payload;
            const chat = state.chats[chatId];
            const chatMessage =
                typeof messageIdOrIndex === 'number'
                    ? chat.messages[messageIdOrIndex]
                    : chat.messages.find((m) => m.id === messageIdOrIndex);
            if (chatMessage) {
                chatMessage[property] = value;
                if (updatedContent) {
                    chatMessage.agentResponse = updatedContent;
                }
            }

            if (frontLoad) {
                frontLoadChat(state, chatId);
            }
        },
        deleteChat: (state: IChatsState, action: PayloadAction<string>) => {
            const keys = Object.keys(state.chats);
            const id = action.payload;

            // If the chat being deleted is the selected chat, select the first remaining chat
            if (id === state.selectedId) {
                if (keys.length > 1) {
                    state.selectedId = id === keys[0] ? keys[1] : keys[0];
                } else {
                    state.selectedId = '';
                }
            }

            const { [id]: _, ...rest } = state.chats;
            state.chats = rest;
        },
    },
});

const frontLoadChat = (state: IChatsState, id: string) => {
    const chat = state.chats[id];
    const { [id]: _, ...rest } = state.chats;
    state.chats = { [id]: chat, ...rest };
};

const updateChat = (state: IChatsState, chatId: string, message: ChatMessageHeader) => {
    state.chats[chatId].messages.push({
        ...message,
    });

    frontLoadChat(state, chatId);
};

export const {
    setChats,
    editChatTitle,
    editChatInput,
    editChatSettings,
    setSelectedChat,
    addChat,
    addMessageToChatFromUser,
    addMessageToChatFromServer,
    updateMessageProperty,
    updateBotResponseStatus,
    deleteChat,
} = chatsSlice.actions;

export default chatsSlice.reducer;
