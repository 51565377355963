import * as signalR from '@microsoft/signalr';
import { Constants } from 'shared/Constants';
import { ChatMessageHeader, CollectionHeader, FeatureFlagHeader } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { StoreMiddlewareAPI } from 'shared/redux/app/store';
import { addAlert, setServiceInfo } from 'shared/redux/features/app/appSlice';
import { BackendServiceUrl } from 'shared/services/BaseService';

/*
 * This is a module that encapsulates the SignalR connection
 * to the messageRelayHub on the server.
 */

// These have to match the callback names used in the backend
const enum SignalRCallbackMethods {
    ReceiveMessage = 'ReceiveMessage',
    ReceiveMessageUpdate = 'ReceiveMessageUpdate',
    CollectionEdited = 'CollectionEdited',
    CollectionDeleted = 'CollectionDeleted',
    FeatureFlagsUpdated = 'FeatureFlagsUpdated',
}

// Set up a SignalR connection to the messageRelayHub on the server
const setupSignalRConnectionToChatHub = () => {
    const connectionHubUrl = new URL('/MessageHub', BackendServiceUrl);
    const signalRConnectionOptions = {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        logger: signalR.LogLevel.Warning,
    };

    // Create the connection instance
    // withAutomaticReconnect will automatically try to reconnect and generate a new socket connection if needed
    const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(connectionHubUrl.toString(), signalRConnectionOptions)
        .withAutomaticReconnect()
        .withHubProtocol(new signalR.JsonHubProtocol())
        .configureLogging(signalR.LogLevel.Information)
        .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    hubConnection.serverTimeoutInMilliseconds = 60000;

    return hubConnection;
};

const registerCommonSignalConnectionEvents = (hubConnection: signalR.HubConnection, store: StoreMiddlewareAPI) => {
    // Re-establish the connection if connection dropped
    hubConnection.onclose((error) => {
        if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
            const errorMessage = 'Connection closed due to error. Try refreshing this page to restart the connection';
            store.dispatch(
                addAlert({
                    message: String(errorMessage),
                    type: AlertType.Error,
                    id: Constants.app.CONNECTION_ALERT_ID,
                }),
            );
            console.log(errorMessage, error);
        }
    });

    hubConnection.onreconnecting((error) => {
        if (hubConnection.state === signalR.HubConnectionState.Reconnecting) {
            const errorMessage = 'Connection lost due to error. Reconnecting...';
            store.dispatch(
                addAlert({
                    message: String(errorMessage),
                    type: AlertType.Info,
                    id: Constants.app.CONNECTION_ALERT_ID,
                }),
            );
            console.log(errorMessage, error);
        }
    });

    hubConnection.onreconnected((connectionId = '') => {
        if (hubConnection.state === signalR.HubConnectionState.Connected) {
            const message = 'Connection re-established. Please refresh the page to ensure you have the latest data.';
            store.dispatch(addAlert({ message, type: AlertType.Success, id: Constants.app.CONNECTION_ALERT_ID }));
            console.log(message + ` Connected with connectionId ${connectionId}`);
        }
    });
};

const startSignalRConnection = (hubConnection: signalR.HubConnection, store: StoreMiddlewareAPI) => {
    registerCommonSignalConnectionEvents(hubConnection, store);
    hubConnection
        .start()
        .then(() => {
            console.assert(hubConnection.state === signalR.HubConnectionState.Connected);
            console.log('SignalR connection established');
        })
        .catch((err) => {
            console.assert(hubConnection.state === signalR.HubConnectionState.Disconnected);
            console.error('SignalR Connection Error: ', err);
            setTimeout(() => {
                startSignalRConnection(hubConnection, store);
            }, 5000);
        });
};

const registerSignalREvents = (hubConnection: signalR.HubConnection, store: StoreMiddlewareAPI) => {
    hubConnection.on(SignalRCallbackMethods.ReceiveMessage, (chatId: string, message: ChatMessageHeader) => {
        store.dispatch({
            type: 'chats/addMessageToChatFromServer',
            payload: { chatId, message: message },
        });
    });

    hubConnection.on(SignalRCallbackMethods.ReceiveMessageUpdate, (chatId: string, message: ChatMessageHeader) => {
        // If tokenUsage is defined, that means full message content has already been streamed and updated from server. No need to update content again.
        store.dispatch({
            type: 'chats/updateMessageProperty',
            payload: {
                chatId: chatId,
                messageIdOrIndex: message.id,
                property: message.tokenUsage ? 'tokenUsage' : 'agentResponse',
                value: message.tokenUsage ? message.tokenUsage : message.agentResponse,
                updatedContent: message.agentResponse,
                frontLoad: true,
            },
        });
    });

    hubConnection.on(SignalRCallbackMethods.CollectionEdited, (collectionId: string, collection: CollectionHeader) => {
        store.dispatch({
            type: 'collections/editCollectionName',
            payload: {
                id: collectionId,
                newName: collection.name,
            },
        });
    });

    hubConnection.on(SignalRCallbackMethods.CollectionDeleted, (collectionId: string) => {
        store.dispatch({
            type: 'collections/deleteCollection',
            payload: collectionId,
        });
    });

    hubConnection.on(SignalRCallbackMethods.FeatureFlagsUpdated, (featureFlags: FeatureFlagHeader) => {
        const existing = store.getState().app.serviceInfo;
        store.dispatch(setServiceInfo({ ...existing, settings: { ...existing.settings, featureFlags } }));
    });
};

// This is a singleton instance of the SignalR connection
let hubConnection: signalR.HubConnection | undefined = undefined;

// This function will return the singleton instance of the SignalR connection
export const getOrCreateHubConnection = (store: StoreMiddlewareAPI) => {
    if (hubConnection === undefined) {
        hubConnection = setupSignalRConnectionToChatHub();

        // Start the signalR connection to make sure messages are
        // sent to all clients and received by all clients
        startSignalRConnection(hubConnection, store);
        registerSignalREvents(hubConnection, store);
    }

    return hubConnection;
};
