import {
    Menu,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components';
import { CollectionsAdd24Regular } from '@fluentui/react-icons';
import { FC } from 'react';
import { useCollection } from 'modules/core/hooks/useCollection';
import { useServiceInfo } from 'modules/core/hooks/useServiceInfo';

interface Props {
    children: JSX.Element;
}

export const NewCollectionMenu: FC<Props> = ({ children }) => {
    const { createCollection } = useCollection();
    const { isAdmin, otherRoles } = useServiceInfo();

    const onAddPrivateCollection = () => {
        void createCollection(true);
    };

    const onAddOtherCollection = (role: string) => {
        void createCollection(false, role);
    };

    const onAddPublicCollection = () => {
        void createCollection(false);
    };

    return (
        <div>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Tooltip content="Add new collection" relationship="label">
                        {children}
                    </Tooltip>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuGroup>
                            <MenuGroupHeader>File Upload</MenuGroupHeader>
                            <MenuItem
                                key="addNewPrivateChatMenuItem"
                                data-testid="addNewPrivateChatMenuItem"
                                icon={<CollectionsAdd24Regular />}
                                onClick={onAddPrivateCollection}
                            >
                                Add Private Collection
                            </MenuItem>
                            {isAdmin && (
                                <MenuItem
                                    key="addNewPublicChatMenuItem"
                                    data-testid="addNewPublicChatMenuItem"
                                    icon={<CollectionsAdd24Regular />}
                                    onClick={onAddPublicCollection}
                                >
                                    Add Public Collection
                                </MenuItem>
                            )}
                            {otherRoles.map((otherRole, i) => {
                                return (
                                    <MenuItem
                                        icon={<CollectionsAdd24Regular />}
                                        onClick={() => {
                                            onAddOtherCollection(otherRole.role);
                                        }}
                                        key={i}
                                    >
                                        Add {otherRole.name} Collection
                                    </MenuItem>
                                );
                            })}
                        </MenuGroup>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    );
};
