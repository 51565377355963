import { makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';
import { GetResponseOptions } from 'modules/core/hooks/useChat';
import { ChatMessageHeader } from 'shared/libs/generated';
import { ChatHistoryItem } from './ChatHistoryItem';

const useClasses = makeStyles({
    root: {
        gap: tokens.spacingVerticalM,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '80%',
        width: '100%',
        justifySelf: 'center',
        marginLeft: '0.4rem', // Offset the width of the scrollbar so it looks centered compared to input box
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
    },
});

interface ChatHistoryProps {
    messages: ChatMessageHeader[];
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ messages, onSubmit }) => {
    const classes = useClasses();

    return (
        <div className={classes.root}>
            {messages.map((message, index) => {
                const isLatest = index === messages.length - 1;
                return (
                    <ChatHistoryItem
                        key={index}
                        isLatest={isLatest}
                        message={message}
                        messageIndex={index}
                        onSubmit={onSubmit}
                    />
                );
            })}
        </div>
    );
};
