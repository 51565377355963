import { useMemo } from 'react';
import { ServiceInfoService } from 'modules/core/services/ServiceInfoService';
import { Constants } from 'shared/Constants';
import { useAuth } from 'shared/hooks/useAuth';
import { UpdateAdminSettingRequest } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { addAlert, setServiceInfo } from 'shared/redux/features/app/appSlice';
import { getFormattedRoleName } from 'shared/utils/CollectionUtils';
import { getErrorDetails } from 'shared/utils/TextUtils';

export const useServiceInfo = () => {
    const dispatch = useAppDispatch();
    const serviceInfoService = new ServiceInfoService();

    const { serviceInfo } = useAppSelector((state) => state.app);
    const { getToken } = useAuth();

    const isAdmin = useMemo(() => {
        return !!serviceInfo.roles.find((role) => role === Constants.roles.admin);
    }, [serviceInfo.roles]);

    const isAnyRoles = useMemo(() => {
        return serviceInfo.roles.length > 0;
    }, [serviceInfo.roles]);

    const otherRoles = useMemo(() => {
        return serviceInfo.roles
            .filter((role) => role !== Constants.roles.admin && role !== Constants.roles.user)
            .map((role) => {
                return {
                    name: getFormattedRoleName(role),
                    role: role,
                };
            });
    }, [serviceInfo.roles]);

    const getServiceInfo = async () => {
        try {
            return await serviceInfoService.retrieveServiceInfoAsync(await getToken());
        } catch (e) {
            const errorDetails = getErrorDetails(e);
            const errorMessage = `Error getting service options. Details: ${errorDetails}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            throw new Error(errorMessage);
        }
    };

    const getAdminSettings = async () => {
        try {
            return await serviceInfoService.retrieveAdminSettingsAsync();
        } catch (e) {
            const errorDetails = getErrorDetails(e);
            const errorMessage = `Error getting admin settings. Details: ${errorDetails}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            throw new Error(errorMessage);
        }
    };

    const updateAdminSettings = async (body: UpdateAdminSettingRequest) => {
        try {
            const result = await serviceInfoService.updateAdminSettingsAsync(await getToken(), body);

            dispatch(setServiceInfo({ ...serviceInfo, settings: result }));

            return result;
        } catch (e) {
            const errorDetails = getErrorDetails(e);
            const errorMessage = `Error updating admin settings. Details: ${errorDetails}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }

        return undefined;
    };

    return {
        getServiceInfo,
        getAdminSettings,
        updateAdminSettings,
        isAdmin,
        otherRoles,
        isAnyRoles,
    };
};
