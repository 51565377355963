import { Button, makeStyles, tokens, Tab, TabList } from '@fluentui/react-components';
import { Save20Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useServiceInfo } from 'modules/core/hooks/useServiceInfo';
import { useTheme } from 'shared/hooks/useTheme';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { AdminSettingsFeatureFlagsTab } from './AdminSettingsFeatureFlagsTab';
import { AdminSettingsImageTab } from './AdminSettingsImageTab';
import { AdminSettingsStyleTab } from './AdminSettingsStyleTab';
import { AdminSettingsTermsTab } from './AdminSettingsTermsTab';

export const useAdminClasses = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        padding: tokens.spacingVerticalXL,
        backgroundColor: tokens.colorNeutralBackground4,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    subContainer: {
        width: '50%',
        height: '100%',
        padding: tokens.spacingVerticalXL,
        backgroundColor: tokens.colorNeutralBackground4,
        justifyContent: 'center',
    },
    cardContainer: {
        display: 'flex',
        gap: tokens.spacingHorizontalXL,
        justifyContent: 'space-evenly',
    },
    tabList: {
        display: 'flex',
        gap: tokens.spacingHorizontalXL,
        justifyContent: 'center',
        marginBottom: tokens.spacingVerticalXL,
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: tokens.spacingVerticalM,
        padding: tokens.spacingVerticalXL,
    },
    brandColorGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        justifyItems: 'space-between',
        columnGap: tokens.spacingHorizontalXXL,
        rowGap: tokens.spacingVerticalM,
    },
    brandColorsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tab: {
        padding: tokens.spacingHorizontalL,
    },
    buttonGroup: {
        marginTop: tokens.spacingVerticalXL,
        display: 'flex',
        gap: tokens.spacingHorizontalS,
        justifyContent: 'flex-end',
    },
    title: {
        fontSize: tokens.fontSizeBase600,
        fontWeight: tokens.fontWeightBold,
    },
    subtitle: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
        marginBottom: tokens.spacingVerticalM,
    },
    group: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        marginBottom: tokens.spacingVerticalXL,
    },
    colorPicker: {
        border: 'none',
        borderRadius: '4px',
        width: '80px',
        height: '40px',
        cursor: 'pointer',
    },
    corner: {
        alignSelf: 'flex-end',
        position: 'absolute',
    },
    textarea: {
        width: '80%',
        height: '50vh',
        padding: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase300,
        borderRadius: tokens.borderRadiusMedium,
    },
    checkAndText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

type TabValue = 'style' | 'images' | 'terms' | 'featureFlags';

export const AdminSettingsView = () => {
    const classes = useAdminClasses();
    const settings = useAppSelector((state: RootState) => state.app.serviceInfo.settings);
    const dispatch = useAppDispatch();

    const { updateAdminSettings } = useServiceInfo();
    const { chat: originalChatIcon, logo: originalLogo, updateTheme } = useTheme();

    const [featureFlags, setFeatureFlags] = useState(settings.featureFlags);
    const [termsOfUse, setTermsOfUse] = useState<string>(settings.termsOfUse);
    const [style, setStyle] = useState<Record<string, string>>(settings.colors);
    const [logo, setLogo] = useState<string | undefined>(originalLogo);
    const [chat, setChat] = useState<string | undefined>(originalChatIcon);
    const [uploadedLogo, setUploadedLogo] = useState<File | undefined>(undefined);
    const [uploadedChat, setUploadedChat] = useState<File | undefined>(undefined);

    const [currentTab, setCurrentTab] = useState<TabValue>('style');

    useEffect(() => {
        updateTheme(style);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [style]);

    const handleCancel = () => {
        setStyle(settings.colors);
        setLogo(originalLogo);
        setChat(originalChatIcon);
        setTermsOfUse(settings.termsOfUse);

        updateTheme(settings.colors);
    };

    const handleSave = () => {
        updateAdminSettings({
            colors: style,
            files: [uploadedLogo, uploadedChat].filter((file) => file !== undefined) as File[],
            termsOfUse,
            featureFlags,
        })
            .then(() => {
                dispatch(addAlert({ message: 'Settings were updated successfully', type: AlertType.Success }));
            })
            .catch(() => {
                dispatch(addAlert({ message: 'Failed to save settings', type: AlertType.Error }));
            });
    };

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <TabList
                    className={classes.tabList}
                    selectedValue={currentTab}
                    size="large"
                    onTabSelect={(_event, data) => {
                        setCurrentTab(data.value as TabValue);
                    }}
                >
                    <Tab value="style" className={classes.tab}>
                        Style
                    </Tab>
                    <Tab value="images" className={classes.tab}>
                        Images
                    </Tab>
                    <Tab value="terms" className={classes.tab}>
                        Terms
                    </Tab>
                    <Tab value="featureFlags" className={classes.tab}>
                        Features
                    </Tab>
                </TabList>

                <div className={classes.cardContainer}>
                    {currentTab === 'style' && <AdminSettingsStyleTab style={style} setStyle={setStyle} />}

                    {currentTab === 'images' && (
                        <AdminSettingsImageTab
                            logo={logo}
                            chat={chat}
                            setLogo={setLogo}
                            setChat={setChat}
                            setUploadedLogo={setUploadedLogo}
                            setUploadedChat={setUploadedChat}
                        />
                    )}

                    {currentTab === 'terms' && <AdminSettingsTermsTab terms={termsOfUse} setTerms={setTermsOfUse} />}

                    {currentTab === 'featureFlags' && (
                        <AdminSettingsFeatureFlagsTab featureFlags={featureFlags} setFeatureFlags={setFeatureFlags} />
                    )}
                </div>

                <div className={classes.buttonGroup}>
                    <Button appearance="primary" onClick={handleSave} icon={<Save20Regular />}>
                        Save
                    </Button>
                    <Button
                        title="Cancels and undo any pending unsaved changes"
                        appearance="secondary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};
