/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RetrieveFeatureFlagsResponse } from '../models/RetrieveFeatureFlagsResponse';
import type { RetrieveFrontendAuthConfigResponse } from '../models/RetrieveFrontendAuthConfigResponse';
import type { RetrieveServiceInfoResponse } from '../models/RetrieveServiceInfoResponse';
import type { RetrieveSettingsResponse } from '../models/RetrieveSettingsResponse';
import type { UpdateAdminSettingRequest } from '../models/UpdateAdminSettingRequest';
import type { UpdateAdminSettingResponse } from '../models/UpdateAdminSettingResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ServiceApiService {
    /**
     * @returns RetrieveFeatureFlagsResponse Success
     * @throws ApiError
     */
    public static retrieveFeatureFlagsEndpoint(): CancelablePromise<RetrieveFeatureFlagsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/service/Info/Features',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns RetrieveFrontendAuthConfigResponse Success
     * @throws ApiError
     */
    public static retrieveFrontendAuthConfigEndpoint(): CancelablePromise<RetrieveFrontendAuthConfigResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/service/AuthConfig',
        });
    }
    /**
     * @returns RetrieveServiceInfoResponse Success
     * @throws ApiError
     */
    public static retrieveServiceInfoEndpoint(): CancelablePromise<RetrieveServiceInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/service/Info',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static retrieveServiceStatusEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/service/Status',
        });
    }
    /**
     * @returns RetrieveSettingsResponse Success
     * @throws ApiError
     */
    public static retrieveSettingsEndpoint(): CancelablePromise<RetrieveSettingsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/service/Info/Settings',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param formData
     * @returns UpdateAdminSettingResponse Success
     * @throws ApiError
     */
    public static updateAdminSettingsEndpoint(
        formData: UpdateAdminSettingRequest,
    ): CancelablePromise<UpdateAdminSettingResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/service/Configuration',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
