import {
    Button,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuPopover,
    MenuList,
    makeStyles,
    mergeClasses,
    Text,
    tokens,
    shorthands,
    Input,
} from '@fluentui/react-components';
import { Checkmark20Regular, Dismiss20Regular, Edit24Regular, MoreHorizontal20Regular } from '@fluentui/react-icons';
import { FC, useState } from 'react';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalL),
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalM,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    previewText: {
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground4,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actions: {
        marginLeft: tokens.spacingHorizontalS,
        alignSelf: 'center',
    },
    selected: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    ellipses: {
        marginTop: 'auto',
    },
    buttons: {
        display: 'flex',
        gap: tokens.spacingHorizontalXS,
    },
});

interface IAction {
    icon: JSX.Element;
    label: string;
    onClick: () => void;
    disabled?: boolean;
    hidden?: boolean;
}

interface ICollapsableListItemProps {
    id: string;
    header: string;
    preview: string;
    isSelected?: boolean;
    extraActions?: IAction[];
    onClick: () => void;
    onEditName?: (newName: string) => void;
}

export const CollapsableListItem: FC<ICollapsableListItemProps> = ({
    id,
    header,
    preview,
    isSelected = false,
    extraActions = [],
    onClick,
    onEditName,
}) => {
    const classes = useClasses();
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(header);

    const handleSave = () => {
        onEditName?.(newName);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setNewName(header);
        setIsEditing(false);
    };

    return (
        <div
            className={mergeClasses(classes.root, isSelected && classes.selected)}
            onClick={onClick}
            aria-label={`List item: ${header}`}
        >
            <div className={classes.body}>
                <div className={classes.header}>
                    {isEditing ? (
                        <>
                            <Input
                                value={newName}
                                onChange={(_e, data) => {
                                    setNewName(data.value);
                                }}
                                aria-label="Edit chat name"
                                autoFocus
                            />
                            <div className={classes.buttons}>
                                <Button icon={<Checkmark20Regular />} onClick={handleSave} appearance="transparent" />
                                <Button icon={<Dismiss20Regular />} onClick={handleCancel} appearance="transparent" />
                            </div>
                        </>
                    ) : (
                        <Text className={classes.title} title={header}>
                            {header}
                        </Text>
                    )}
                </div>

                <Text className={classes.previewText} size={200}>
                    {preview}
                </Text>
            </div>

            {!isEditing && (
                <div className={classes.actions}>
                    <Menu
                        onOpenChange={(e, _data) => {
                            e.stopPropagation();
                        }}
                    >
                        <MenuTrigger disableButtonEnhancement>
                            <Button
                                className={classes.ellipses}
                                icon={<MoreHorizontal20Regular />}
                                appearance="subtle"
                            />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    icon={<Edit24Regular />}
                                    disabled={!onEditName}
                                    onClick={() => {
                                        setIsEditing(true);
                                    }}
                                >
                                    Edit Name
                                </MenuItem>
                                {extraActions
                                    .filter((x) => !x.hidden)
                                    .map((action, index) => (
                                        <MenuItem
                                            disabled={action.disabled}
                                            key={`${id}-action-${index}`}
                                            icon={action.icon}
                                            onClick={action.onClick}
                                        >
                                            {action.label}
                                        </MenuItem>
                                    ))}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div>
            )}
        </div>
    );
};
