import {
    Configuration,
    EndSessionRequest,
    IPublicClientApplication,
    InteractionStatus,
    LogLevel,
} from '@azure/msal-browser';
import { Constants } from 'shared/Constants';
import { store } from 'shared/redux/app/store';
import { TokenHelper } from './TokenUtils';

export const enum AuthMode {
    PassThrough = 'PassThrough',
    AzureEntraId = 'AzureEntraId',
}

export interface AuthConfig {
    mode: AuthMode;
    authority: string;
    clientId: string;
    apiScope: string;
}

const getMsalConfig = (authConfig: AuthConfig): Configuration => ({
    auth: {
        clientId: authConfig.clientId,
        authority: authConfig.authority,
        redirectUri: window.origin,
    },
    cache: Constants.msal.cache,
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }

                switch (level) {
                    case LogLevel.Error:
                        console.log('error:', message);
                        return;

                    case LogLevel.Info:
                        console.log('info:', message);
                        return;

                    case LogLevel.Verbose:
                        console.log('verbose:', message);
                        return;

                    case LogLevel.Warning:
                        console.warn('warn:', message);
                        return;

                    default:
                        console.log(message);
                }
            },
        },
        windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
        iframeHashTimeout: 9000, // Applies just to silent calls - In milliseconds
        loadFrameTimeout: 9000, // Applies to both silent and popup calls - In milliseconds
    },
});

const getMsalScopes = () => {
    const apiScope = getAuthConfig()?.apiScope;
    return Constants.msal.appScopes.concat(apiScope ?? []);
};

const logoutRequest: EndSessionRequest = {
    postLogoutRedirectUri: window.origin,
};

const ssoSilentRequest = async (msalInstance: IPublicClientApplication) => {
    await msalInstance.ssoSilent({
        account: msalInstance.getActiveAccount() ?? undefined,
        scopes: getMsalScopes(),
    });
};

const loginAsync = async (instance: IPublicClientApplication) => {
    if (Constants.msal.method === 'redirect') {
        await instance.loginRedirect({
            scopes: getMsalScopes(),
            extraScopesToConsent: Constants.msal.msGraphAppScopes,
        });
    } else {
        await instance.loginPopup({
            scopes: getMsalScopes(),
            extraScopesToConsent: Constants.msal.msGraphAppScopes,
        });
    }
};

const logoutAsync = (instance: IPublicClientApplication) => {
    if (Constants.msal.method === 'popup') {
        void instance.logoutPopup(logoutRequest);
    }

    if (Constants.msal.method === 'redirect') {
        void instance.logoutRedirect(logoutRequest);
    }
};

const getAuthConfig = () => store.getState().app.authConfig;
const isAuthEnabled = (config: AuthConfig | null = null) => (config ?? getAuthConfig())?.mode === AuthMode.AzureEntraId;

const getBackendAccessToken = async (instance: IPublicClientApplication, inProgress: InteractionStatus) => {
    return isAuthEnabled() ? await TokenHelper.getAccessTokenUsingMsal(inProgress, instance, getMsalScopes()) : '';
};

export const AuthHelper = {
    getBackendAccessToken,
    getMsalConfig,
    getMsalScopes,
    logoutRequest,
    ssoSilentRequest,
    loginAsync,
    logoutAsync,
    isAuthEnabled,
    getAuthConfig,
};
