import { Image } from '@fluentui/react-components';
import React, { useEffect, useMemo, useState } from 'react';
import { AppState, useClasses } from 'layout/App';
import { Alerts } from 'layout/components/Alerts';
import { UserSettingsMenu } from 'layout/components/header/UserSettingsMenu';
import { BackendProbe } from 'layout/components/views/BackendProbe';
import { Error } from 'layout/components/views/Error';
import { Loading } from 'layout/components/views/Loading';
import { ChatView } from 'modules/core/components/chat/ChatView';
import { CollectionsView } from 'modules/core/components/collection/CollectionsView';
import { PersonaView } from 'modules/core/components/persona/PersonaView';
import { AdminSettingsView } from 'modules/core/components/settings/AdminSettingsView';
import { ServiceInfoService } from 'modules/core/services/ServiceInfoService';
import { useAuth } from 'shared/hooks/useAuth';
import { useTheme } from 'shared/hooks/useTheme';

const AppContentInternal = ({
    classes,
    appState,
    setAppState,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
}) => {
    const [skill, setSkill] = useState<JSX.Element>(<></>);

    const { logo } = useTheme();
    const { token } = useAuth();

    const maintenanceService = useMemo(() => new ServiceInfoService(), []);

    useEffect(() => {
        if (token !== null) {
            void maintenanceService.retrieveServiceStatusAsync(token).then(() => {
                setAppState(AppState.SettingUserInfo);
            });
        }
    }, [token, maintenanceService, setAppState]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Image src={logo} alt="[Arinco Logo]" height={34} />
                    {process.env.REACT_APP_ENV !== 'production' && (
                        <h3 className={classes.environmentTag}>{process.env.REACT_APP_ENV?.toLocaleUpperCase()} APP</h3>
                    )}
                </div>

                <div className={classes.cornerItems}>
                    <div className={classes.cornerItems}>
                        <UserSettingsMenu
                            isCollectionsActive={appState === AppState.Collections}
                            setLoadingState={() => {
                                setAppState(AppState.SigningOut);
                            }}
                            setCollectionState={() => {
                                setAppState(AppState.LoadingCollections);
                            }}
                            setChatState={() => {
                                setAppState(AppState.Chat);
                            }}
                            setPersonaState={() => {
                                setAppState(AppState.LoadingPersonas);
                            }}
                            setAdminSettingsState={() => {
                                setAppState(AppState.AdminSettings);
                            }}
                            setSkillState={(component) => {
                                setAppState(AppState.Skill);
                                setSkill(component);
                            }}
                        />
                    </div>
                </div>
            </div>

            {appState === AppState.ProbeForBackend && <BackendProbe />}

            {appState === AppState.SettingUserInfo && <BackendProbe />}

            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user details. Please try signing out and signing back in.'} />
            )}

            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}

            {appState === AppState.ErrorLoadingCollections && (
                <Error text={'Unable to load collections. Please try refreshing the page.'} />
            )}

            {appState === AppState.ErrorLoadingPersonas && (
                <Error text={'Unable to load personas. Please try refreshing the page.'} />
            )}

            {appState === AppState.ErrorLoadingServiceInfo && (
                <Error text={'Unable to load service information. Please try refreshing the page.'} />
            )}

            {appState === AppState.UserUnauthorized && (
                <Error text={'You have insufficient user role permissions. Please contact your app administrator.'} />
            )}

            {appState === AppState.LoadingServiceInfo && <Loading text="Loading..." />}

            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}

            {appState === AppState.LoadingCollections && <Loading text="Loading collections..." />}

            {appState === AppState.LoadingPersonas && <Loading text="Loading personas..." />}

            {appState === AppState.Chat && <ChatView />}

            {appState === AppState.Collections && <CollectionsView />}

            {appState === AppState.Personas && <PersonaView />}

            {appState === AppState.AdminSettings && <AdminSettingsView />}

            {appState === AppState.Skill && skill}

            <Alerts />
        </div>
    );
};

AppContentInternal.displayName = 'AppContent';

export const AppContent = React.memo(AppContentInternal);
