import { makeStyles, Image } from '@fluentui/react-components';
import { FC } from 'react';
import { useTheme } from 'shared/hooks/useTheme';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        color: '#cbd1fe',
    },
});

export const ChatBotIcon: FC = () => {
    const classes = useClasses();

    const { chat } = useTheme();

    return (
        <div className={classes.container}>
            <Image src={chat} alt="[Company Logo]" height={34} />
        </div>
    );
};
