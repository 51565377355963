import { useMsal } from '@azure/msal-react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import {
    Label,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { FC, useCallback, useMemo, useState } from 'react';
import { InfoDialog } from 'layout/components/header/dialogs/InfoDialog';
import { NavigationTabs } from 'layout/components/header/NavigationTabs';
import { useServiceInfo } from 'modules/core/hooks/useServiceInfo';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState, resetState } from 'shared/redux/app/store';
import { AuthHelper } from 'shared/utils/AuthUtils';
import { getFormattedRoleName } from 'shared/utils/CollectionUtils';

export const useClasses = makeStyles({
    root: {
        marginRight: tokens.spacingHorizontalXL,
    },
    persona: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingVerticalMNudge),
        overflowWrap: 'break-word',
    },
    info: {
        marginRight: tokens.spacingHorizontalM,
    },
    rolesLabel: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalMNudge),
    },
    popoverMenu: {
        marginTop: '10px',
    },
    popover: {
        marginTop: '16px!important',
    },
    tabText: {
        color: '#fff',
        padding: '0px',
    },
    tabListCustom: {
        gap: tokens.spacingHorizontalL,
    },
    menuTab: {
        height: '35px',
    },
});

interface IUserSettingsProps {
    isCollectionsActive: boolean;
    setLoadingState: () => void;
    setCollectionState: () => void;
    setChatState: () => void;
    setPersonaState: () => void;
    setAdminSettingsState: () => void;
    setSkillState: (element: JSX.Element) => void;
}

export const UserSettingsMenu: FC<IUserSettingsProps> = ({
    setLoadingState,
    setCollectionState,
    setChatState,
    setPersonaState,
    setAdminSettingsState,
    setSkillState,
}) => {
    const classes = useClasses();
    const { instance } = useMsal();

    const { serviceInfo } = useAppSelector((state: RootState) => state.app);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { isAnyRoles } = useServiceInfo();

    const [openInfoDialog, setOpenInfoDialog] = useState(false);

    const onLogout = useCallback(() => {
        setLoadingState();
        AuthHelper.logoutAsync(instance);
        resetState();
    }, [instance, setLoadingState]);

    const userRoles = useMemo(() => {
        return serviceInfo.roles.map((role) => getFormattedRoleName(role) ?? '').join(', ');
    }, [serviceInfo.roles]);

    return (
        <>
            <NavigationTabs
                setChatState={setChatState}
                setCollectionState={setCollectionState}
                setPersonaState={setPersonaState}
                setAdminSettingsState={setAdminSettingsState}
                setSkillState={setSkillState}
            />
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Persona
                        className="navbarPersona"
                        text={activeUserInfo?.username}
                        secondaryText={activeUserInfo?.email}
                        size={PersonaSize.size32}
                        imageUrl={activeUserInfo?.photoUrl}
                    />
                </MenuTrigger>

                <MenuPopover className={classes.popover}>
                    <MenuList className={classes.popoverMenu}>
                        <Persona
                            className={classes.persona}
                            text={activeUserInfo?.username}
                            secondaryText={activeUserInfo?.email}
                            size={PersonaSize.size40}
                            imageUrl={activeUserInfo?.photoUrl}
                        />
                        <Label className={classes.rolesLabel}>
                            <strong>Roles:</strong> {isAnyRoles && <span>{userRoles}</span>}
                            {!isAnyRoles && <span>No roles</span>}
                        </Label>

                        <MenuDivider />

                        {isAnyRoles && (
                            <>
                                <MenuItem
                                    data-testid="infoButton"
                                    onClick={() => {
                                        setOpenInfoDialog(true);
                                    }}
                                >
                                    Information & Support
                                </MenuItem>
                            </>
                        )}

                        <MenuItem data-testid="logOutMenuButton" onClick={onLogout}>
                            Sign out
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>

            <InfoDialog
                open={openInfoDialog}
                closeDialog={() => {
                    setOpenInfoDialog(false);
                }}
            />
        </>
    );
};
