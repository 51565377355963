import { Link, Text } from '@fluentui/react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogOpenChangeData,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    makeStyles,
    // shorthands,
    tokens,
} from '@fluentui/react-components';
import React from 'react';
import { AcceptableUseText } from 'layout/components/AcceptableUseText';
import { TokenUsageGraph } from 'shared/components/token-usage/TokenUsageGraph';
import { SharedStyles, useDialogClasses } from 'shared/styles';

const useClasses = makeStyles({
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    outer: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    footer: {
        paddingTop: tokens.spacingVerticalL,
    },
    closeButton: {
        marginTop: tokens.spacingVerticalL,
        marginLeft: 'auto',
        marginRight: tokens.spacingHorizontalL,
    },
    infoScroll: {
        maxHeight: '300px',
        overflowY: 'scroll',
    },
});

interface IInfoDialogProps {
    open: boolean;
    closeDialog: () => void;
}

export const InfoDialog: React.FC<IInfoDialogProps> = ({ open, closeDialog }) => {
    const classes = useClasses();
    const dialogClasses = useDialogClasses();

    return (
        <Dialog
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                if (!data.open) closeDialog();
            }}
        >
            <DialogSurface className={classes.outer}>
                <DialogBody className={classes.root}>
                    <DialogTitle>Information & Support</DialogTitle>

                    <DialogContent className={classes.content}>
                        For more information about this service, please refer to the following links:
                        <br />
                        <br />
                        <strong>About Arinco: </strong>
                        <Link href="https://arinco.com.au/" underline>
                            Arinco website
                        </Link>
                        <br />
                        <strong>Responsible use of AI: </strong>
                        <Link
                            href="https://support.microsoft.com/en-au/topic/what-is-responsible-ai-33fc14be-15ea-4c2c-903b-aa493f5b8d92"
                            underline
                        >
                            Microsoft responsible use of AI
                        </Link>
                        <br />
                        <strong>Data, privacy and security: </strong>
                        <Link
                            href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy"
                            underline
                        >
                            Microsoft data, privacy, and security for Azure OpenAI Service
                        </Link>
                        <br />
                        <br />
                        <Divider />
                        <TokenUsageGraph />
                        <br />
                        <strong>Use of service acknowledgement</strong>
                    </DialogContent>
                    <Text className={classes.infoScroll}>
                        <AcceptableUseText></AcceptableUseText>
                    </Text>
                </DialogBody>

                <DialogActions position="end" className={dialogClasses.footer}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" data-testid="infoCloseButton" className={classes.closeButton}>
                            Close
                        </Button>
                    </DialogTrigger>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};
