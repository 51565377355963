import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentHeader, DocumentHeaderType, DocumentStatus } from 'shared/libs/generated';
import { Collections, ICollectionsState, ICollectionTitleChange, initialState } from './CollectionsState';
import { ICollectionState } from './CollectionState';

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        setCollections: (state: ICollectionsState, action: PayloadAction<Collections>) => {
            state.collections = action.payload;
        },
        editCollectionName: (state: ICollectionsState, action: PayloadAction<ICollectionTitleChange>) => {
            const id = action.payload.id;
            const newName = action.payload.newName;
            state.collections[id].name = newName;
            frontLoadCollection(state, id);
        },
        setSelectedCollection: (state: ICollectionsState, action: PayloadAction<string>) => {
            state.selectedId = action.payload;
        },
        addCollection: (state: ICollectionsState, action: PayloadAction<ICollectionState>) => {
            const newId = action.payload.id;
            state.collections = { [newId]: action.payload, ...state.collections };
            state.selectedId = newId;
        },
        editCollectionDocumentStatus: (
            state: ICollectionsState,
            action: PayloadAction<{ collectionId: string; documentId: string; documentStatus: DocumentStatus }>,
        ) => {
            const collectionId = action.payload.collectionId;
            const collection = state.collections[collectionId];
            const document = collection.documents.find((x) => x.id === action.payload.documentId);
            if (!document) {
                return;
            }

            document.status == action.payload.documentStatus;
            frontLoadCollection(state, collectionId);
        },
        /*
         * addDocumentToCollectionFromUser() and addDocumentToCollectionFromServer() both update the collections state.
         * However they are for different purposes. The former action is for updating the collection from the
         * webapp and will be captured by the SignalR middleware and the payload will be broadcasted to all clients
         * in the same group.
         * The addDocumentToCollectionFromServer() action is triggered by the SignalR middleware when a response is received
         * from the webapi.
         */
        setCollectionDocuments: (
            state: ICollectionsState,
            action: PayloadAction<{ documents: DocumentHeader[]; collectionId: string }>,
        ) => {
            const { documents, collectionId } = action.payload;
            state.collections[collectionId].documents = [...documents];

            frontLoadCollection(state, collectionId);
        },
        addDocumentToCollectionFromUser: (
            state: ICollectionsState,
            action: PayloadAction<{ document: DocumentHeader; collectionId: string }>,
        ) => {
            const { document, collectionId } = action.payload;
            updateCollection(state, collectionId, document);
        },
        addDocumentToCollectionFromServer: (
            state: ICollectionsState,
            action: PayloadAction<{ document: DocumentHeader; collectionId: string }>,
        ) => {
            const { document, collectionId } = action.payload;
            updateCollection(state, collectionId, document);
        },
        updateBotResponseStatus: (
            state: ICollectionsState,
            action: PayloadAction<{ collectionId: string; status: string | undefined }>,
        ) => {
            const { collectionId, status } = action.payload;
            const collection = state.collections[collectionId];
            collection.uploadStatus = status;
        },
        deleteCollection: (state: ICollectionsState, action: PayloadAction<string>) => {
            const keys = Object.keys(state.collections);
            const id = action.payload;

            // If the collection being deleted is the selected collection, select the first remaining collection
            if (id === state.selectedId) {
                if (keys.length > 1) {
                    state.selectedId = id === keys[0] ? keys[1] : keys[0];
                } else {
                    state.selectedId = '';
                }
            }

            const { [id]: _, ...rest } = state.collections;
            state.collections = rest;
        },
        deleteDocumentInCollection: (
            state: ICollectionsState,
            action: PayloadAction<{ collectionId: string; documentId: string }>,
        ) => {
            const collectionId = action.payload.collectionId;
            const collection = state.collections[collectionId];
            const documents = collection.documents.filter((x) => x.id !== action.payload.documentId);
            collection.documents = [...documents];

            frontLoadCollection(state, collectionId);
        },
        importDocuments: (
            state: ICollectionsState,
            action: PayloadAction<{ collectionId: string; importingDocuments: string[] }>,
        ) => {
            const { collectionId, importingDocuments } = action.payload;

            const documents = importingDocuments.map((document, index) => {
                return {
                    id: `in-progress-${index}`,
                    collectionId,
                    name: document,
                    path: '',
                    status: 'Added' as DocumentStatus.ADDED,
                    pageCount: 0,
                    dateCreated: new Date().getTime() + '',
                    dateModified: new Date().getTime() + '',
                    type: DocumentHeaderType.FILE_UPLOAD,
                    allowedReaders: [],
                } as DocumentHeader;
            });

            state.collections[collectionId].documents = [...state.collections[collectionId].documents, ...documents];

            frontLoadCollection(state, collectionId);
        },
    },
});

const frontLoadCollection = (state: ICollectionsState, id: string) => {
    const collection = state.collections[id];
    const { [id]: _, ...rest } = state.collections;
    state.collections = { [id]: collection, ...rest };
};

const updateCollection = (state: ICollectionsState, collectionId: string, document: DocumentHeader) => {
    state.collections[collectionId].documents.push({
        ...document,
    });

    frontLoadCollection(state, collectionId);
};

export const {
    setCollections,
    editCollectionName,
    setSelectedCollection,
    addCollection,
    setCollectionDocuments,
    addDocumentToCollectionFromUser,
    addDocumentToCollectionFromServer,
    updateBotResponseStatus,
    deleteCollection,
    deleteDocumentInCollection,
    importDocuments,
} = collectionsSlice.actions;

export default collectionsSlice.reducer;
