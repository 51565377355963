import { Toaster, ToastIntent } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { useToast } from 'shared/hooks/useToast';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { setAlerts } from 'shared/redux/features/app/appSlice';

export const Alerts: React.FC = () => {
    const { alerts } = useAppSelector((state: RootState) => state.app);
    const { toast, toasterId } = useToast();

    const dispatch = useAppDispatch();

    const convertIntent = (type: AlertType): ToastIntent => {
        const intentMap: { [key in AlertType]: ToastIntent } = {
            [AlertType.Success]: 'success',
            [AlertType.Error]: 'error',
            [AlertType.Info]: 'info',
            [AlertType.Warning]: 'warning',
        };

        return intentMap[type];
    };

    useEffect(() => {
        if (!alerts.length) return;

        alerts.forEach((alert, i) => {
            toast({ title: '', body: alert.message, index: i, intent: convertIntent(alert.type) });
        });

        dispatch(setAlerts([]));
    }, [alerts, toast, dispatch]);

    return <Toaster toasterId={toasterId} limit={5} />;
};
