import {
    Text,
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Card,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnDefinition,
    TableColumnId,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellProps,
    TableRow,
    Tooltip,
    createTableColumn,
    makeStyles,
    shorthands,
    tokens,
    useTableFeatures,
    useTableSort,
} from '@fluentui/react-components';
import {
    Delete24Regular,
    DocumentArrowUp20Regular,
    DocumentPdfRegular,
    DocumentTextRegular,
    FluentIconsProps,
} from '@fluentui/react-icons';
import React, { useMemo, useRef, useState } from 'react';
import { useCollection } from 'modules/core/hooks/useCollection';
import { useServiceInfo } from 'modules/core/hooks/useServiceInfo';
import { Add20, Sync20 } from 'shared/components/BundledIcons';
import { DeleteDialog } from 'shared/components/DeleteDialog';
import { Constants } from 'shared/Constants';
import { useFile } from 'shared/hooks/useFile';
import { DocumentHeader, DocumentHeaderType, DocumentStatus } from 'shared/libs/generated';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { Breakpoints } from 'shared/styles';
import { timestampToDateString } from 'shared/utils/TextUtils';
import { UploadDocumentConfirmationDialog } from './collection-list/dialogs/UploadDocumentConfirmationDialog';
import { CollectionProgressBar } from './CollectionProgressBar';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    title: {
        flex: '1 1 auto', // Allow the title to grow and shrink
        minWidth: 0, // Prevent overflow issues
        marginRight: tokens.spacingHorizontalM, // Space between title and buttons
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalS),
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin(tokens.spacingHorizontalS, '0', tokens.spacingVerticalS, '0'),
    },
    uploadButton: {
        ...shorthands.margin('0', tokens.spacingHorizontalS, '0', '0'),
    },
    refreshButton: {
        ...shorthands.margin('0'),
    },
    table: {
        ...shorthands.margin(tokens.spacingVerticalL, '0'),
        backgroundColor: 'transparent',
    },
    tableHeader: {
        fontWeight: tokens.fontSizeBase600,
    },
    tableColumnWidth5Percent: {
        width: '5%',
    },
    tableColumnWidth10Percent: {
        width: '10%',
    },
    tableColumnWidth15Percent: {
        width: '15%',
    },
    noResults: {
        fontWeight: tokens.fontSizeBase600,
        fontSize: tokens.fontSizeBase400,
        ...shorthands.margin(tokens.spacingVerticalXXXL, tokens.spacingHorizontalNone),
    },
    settingsContent: {
        marginTop: '15px',
    },
    cardCustom: {
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalM),
    },
    progressbar: {
        backgroundColor: '#EAEAEA', // Light gray background for the whole progress bar
        borderRadius: '4px',
        height: '18px',
        position: 'relative',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    headingWithActions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: '10px',
    },
    dialogTitle: {
        fontSize: '24px',
        fontWeight: 600,
        height: '24px',
    },
    collectionsWindow: {
        width: '100%',
        height: 'calc(100% - 40px)', // Temporary until alerts are positioned absolutely
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'var(--colorNeutralBackground4)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem',
        paddingTop: '40px',
    },
    collectionsContent: {
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        ...Breakpoints.large({
            width: '90%',
        }),
    },
    collectionsForm: {
        flex: 3,
    },
    supportingLabelText: {
        fontSize: '12px',
        color: '#665',
    },
});

interface TableItem {
    id: string;
    collectionId: string;
    name: {
        label: string;
        icon: JSX.Element;
        url?: string;
    };
    createdOn: {
        label: string;
        timestamp: string;
    };
    size: string;
    status: DocumentStatus;
    canBeDeleted: boolean;
}

export const CollectionSettings: React.FC = () => {
    const [resources, setResources] = useState<DocumentHeader[]>([]);
    const [isConfirmingUpload, setIsConfirmingUpload] = useState<boolean>(false);
    const [isConfirmingDocumentDelete, setIsConfirmingDocumentDelete] = useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] = useState<DocumentHeader>();

    const { collections, selectedId } = useAppSelector((state: RootState) => state.collections);
    const { refreshCollection, deleteCollectionDocument } = useCollection();
    const { handleImport } = useFile();
    const { isAdmin } = useServiceInfo();

    const classes = useClasses();
    const localDocumentFileRef = useRef<HTMLInputElement | null>(null);

    const canEdit = useMemo(() => {
        const currentCollection = collections[selectedId];
        const isCollectionPrivate = currentCollection.isPrivate;
        if (isCollectionPrivate || !!currentCollection.role) {
            return true;
        }

        return isAdmin;
    }, [collections, selectedId, isAdmin]);

    React.useEffect(() => {
        const collection = collections[selectedId];
        if (collection.type !== DocumentHeaderType.SHARE_POINT) {
            return;
        }
    }, [collections, selectedId]);

    React.useEffect(() => {
        setResources([...collections[selectedId].documents]);
    }, [collections, selectedId]);

    const onUploadDialogConfirm = () => {
        localDocumentFileRef.current?.click();
        setIsConfirmingUpload(false);
    };

    const onDeleteDocument = (documentId: string) => {
        const document = collections[selectedId].documents.find((x) => x.id == documentId);
        if (!document) {
            return;
        }

        setSelectedDocument(document);
        setIsConfirmingDocumentDelete(true);
    };

    const onDeleteDocumentDialogConfirm = (document: DocumentHeader) => {
        setIsConfirmingDocumentDelete(false);
        void deleteCollectionDocument(document.collectionId, document.id);
    };

    const onRefresh = async () => {
        await refreshCollection(selectedId);
    };

    const { columns, rows } = useTable(resources, onDeleteDocument, canEdit);

    const isFileUploadCollection = collections[selectedId].type === DocumentHeaderType.FILE_UPLOAD;

    const title = useMemo(() => {
        const collection = collections[selectedId];

        return collection.name;
    }, [collections, selectedId]);

    const buttons = canEdit ? (
        <div className={classes.functional}>
            {/* Hidden input for file upload. Only accept .pdf files for now. */}
            <input
                type="file"
                ref={localDocumentFileRef}
                style={{ display: 'none' }}
                accept={Constants.app.importTypes}
                multiple={true}
                onChange={() => {
                    void handleImport(selectedId, { type: 'ref', ref: localDocumentFileRef });
                }}
            />
            {isFileUploadCollection && (
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Tooltip content="Embed file into collection" relationship="label">
                            <Button className={classes.uploadButton} icon={<DocumentArrowUp20Regular />}>
                                Upload
                            </Button>
                        </Tooltip>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem
                                data-testid="addNewLocalDoc"
                                onClick={() => {
                                    setIsConfirmingUpload(true);
                                }}
                                icon={<Add20 />}
                            >
                                New document
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )}
            <Button
                className={classes.refreshButton}
                icon={<Sync20 />}
                onClick={() => {
                    void onRefresh();
                }}
            >
                Refresh
            </Button>
        </div>
    ) : null;

    return (
        <div className={classes.collectionsWindow}>
            <div className={classes.collectionsContent}>
                <div className={classes.collectionsForm}>
                    <div className={classes.header}>
                        <div className={classes.headingWithActions}>
                            <Text className={classes.dialogTitle}>{title}</Text>
                            {buttons}
                        </div>
                        <Text className={classes.supportingLabelText}>
                            Collections are groups of documents you upload, enabling the AI to reference and use them in
                            chats and other skills.
                        </Text>
                    </div>
                    <div className={classes.settingsContent}>
                        {rows.length > 0 && (
                            <Card className={classes.cardCustom}>
                                <Table aria-label="External resource table" className={classes.table}>
                                    <TableHeader>
                                        <TableRow>{columns.map((column) => column.renderHeaderCell())}</TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {rows.map((item) => (
                                            <TableRow key={item.id}>
                                                {columns.map((column) => column.renderCell(item))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Card>
                        )}

                        {!rows.length && (
                            <div className={classes.noResults}>There are no documents for this collection.</div>
                        )}

                        <UploadDocumentConfirmationDialog
                            open={isConfirmingUpload}
                            onConfirm={onUploadDialogConfirm}
                            onClose={() => {
                                setIsConfirmingUpload(false);
                            }}
                        />

                        <DeleteDialog
                            open={isConfirmingDocumentDelete}
                            onClose={() => {
                                setIsConfirmingDocumentDelete(false);
                            }}
                            subject={selectedDocument?.name ?? ''}
                            description="This action will remove the document and may break existing chat citations."
                            onConfirm={() => {
                                if (selectedDocument) {
                                    onDeleteDocumentDialogConfirm(selectedDocument);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

function useTable(resources: DocumentHeader[], onDeleteDocument: (documentId: string) => void, canEdit: boolean) {
    const collection = useCollection();
    const classes = useClasses();

    const headerSortProps = (columnId: TableColumnId): TableHeaderCellProps => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });

    const onFileClick = async (item: TableItem) => {
        const itemUrl = item.name.url ?? '';
        if (!itemUrl) {
            return;
        }

        const url = await collection.getBlobKeyForUpload(itemUrl, item.id);
        if (!url) {
            return;
        }

        window.open(url, '_blank', 'rel=noopener noreferrer');
    };

    const columns: Array<TableColumnDefinition<TableItem>> = [
        createTableColumn<TableItem>({
            columnId: 'name',
            renderHeaderCell: () => (
                <TableHeaderCell key="name" {...headerSortProps('name')}>
                    Name
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={item.id}>
                    <TableCellLayout media={item.name.icon} truncate>
                        <a href="#" title={item.name.label} onClick={() => void onFileClick(item)}>
                            {item.name.label}
                        </a>
                    </TableCellLayout>
                </TableCell>
            ),
            compare: (a, b) => {
                const comparison = a.name.label.localeCompare(b.name.label);
                return getSortDirection('name') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'createdOn',
            renderHeaderCell: () => (
                <TableHeaderCell key="createdOn" {...headerSortProps('createdOn')} style={{ width: '15%' }}>
                    Created on
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={item.createdOn.timestamp} title={new Date(item.createdOn.timestamp).toLocaleString()}>
                    {item.id.startsWith('in-progress') ? 'N/A' : new Date(item.createdOn.timestamp).toLocaleString()}
                </TableCell>
            ),
            compare: (a, b) => {
                const comparison = a.createdOn.timestamp > b.createdOn.timestamp ? 1 : -1;
                return getSortDirection('createdOn') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'fileSize',
            renderHeaderCell: () => (
                <TableHeaderCell
                    key="fileSize"
                    {...headerSortProps('fileSize')}
                    className={classes.tableColumnWidth15Percent}
                >
                    Size
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={`${item.id}-tokens`}>
                    {item.id.startsWith('in-progress') ? 'N/A' : item.size.toLocaleString()}
                </TableCell>
            ),
            compare: (a, b) => {
                const comparison = a.size > b.size ? 1 : -1;
                return getSortDirection('fileSize') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'progress',
            renderHeaderCell: () => (
                <TableHeaderCell
                    key="progress"
                    {...headerSortProps('progress')}
                    className={classes.tableColumnWidth10Percent}
                >
                    Upload status
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={`${item.id}-progress`}>
                    <CollectionProgressBar status={item.status} />
                </TableCell>
            ),
            compare: (a, b) => {
                const comparison = a.status.localeCompare(b.status);
                return getSortDirection('progress') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'actions',
            renderHeaderCell: () => (
                <TableHeaderCell key="actions" className={classes.tableColumnWidth5Percent}>
                    Actions
                </TableHeaderCell>
            ),
            renderCell: (item) => {
                if (!item.canBeDeleted) {
                    return <TableCell key={`${item.id}-actions`}></TableCell>;
                }

                return (
                    <TableCell key={`${item.id}-actions`}>
                        {!item.id.startsWith('in-progress') &&
                            item.status.toLowerCase() !== DocumentStatus.DELETING.toLowerCase() &&
                            canEdit && (
                                <Button
                                    data-testid="deleteDocument"
                                    icon={<Delete24Regular color={tokens.colorStatusDangerForeground1} />}
                                    appearance="transparent"
                                    onClick={() => {
                                        onDeleteDocument(item.id);
                                    }}
                                />
                            )}
                    </TableCell>
                );
            },
        }),
    ];

    const items = resources.map((item) => {
        const tableItem: TableItem = {
            id: item.id,
            collectionId: item.collectionId,
            name: {
                label: item.name,
                icon: getFileIconByFileExtension(item.name),
                url: item.path, // TODO: Construct the link
            },
            createdOn: {
                label: timestampToDateString(item.dateCreated),
                timestamp: item.dateCreated,
            },
            size: `${item.pageCount ?? 0} ${item.name.endsWith('pdf') ? 'pages' : 'characters'}`,
            status: item.status,
            canBeDeleted: item.type === DocumentHeaderType.FILE_UPLOAD,
        };
        return tableItem;
    });

    const {
        sort: { getSortDirection, toggleColumnSort, sortColumn },
    } = useTableFeatures(
        {
            columns,
            items,
        },
        [
            useTableSort({
                defaultSortState: { sortColumn: 'createdOn', sortDirection: 'descending' },
            }),
        ],
    );

    if (sortColumn) {
        items.sort((a, b) => {
            const compare = columns.find((column) => column.columnId === sortColumn)?.compare;
            return compare?.(a, b) ?? 0;
        });
    }

    return { columns, rows: items };
}

export function getFileIconByFileExtension(fileName: string, props: FluentIconsProps = {}) {
    const extension = fileName.toLowerCase().substring(fileName.lastIndexOf('.') + 1);
    if (extension === 'pdf') {
        return <DocumentPdfRegular {...props} />;
    }

    return <DocumentTextRegular {...props} />;
}
