import { Dispatch, MiddlewareAPI, configureStore } from '@reduxjs/toolkit';
import { IChatsState } from 'modules/core/redux/chats/ChatState';
import { ICollectionsState } from 'modules/core/redux/collections/CollectionsState';
import { IPersonasState } from 'modules/core/redux/personas/PersonasState';
import { AppState } from 'shared/redux/features/app/AppState';
import { signalRMiddleware } from 'shared/redux/features/message-relay/signalRMiddleware';
import { IThemeState } from 'shared/redux/features/theme/themeState';
import { UsersState } from 'shared/redux/features/users/UsersState';
import { createPersistMiddleware } from './persistMiddleware';
import { ReducerManager, reducerManager } from './reducerManager';
import resetStateReducer, { resetApp } from './rootReducer';

const persistAppSettingsMiddleware = createPersistMiddleware({
    sliceName: 'app',
    propertyPath: 'serviceInfo.settings',
    storageKey: 'adminsettings',
});

export type StoreMiddlewareAPI = MiddlewareAPI<Dispatch, RootState>;
export type Store = typeof store & { reducerManager: ReducerManager };
const tempStore = configureStore({
    reducer: resetStateReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(signalRMiddleware).concat(persistAppSettingsMiddleware),
});

export const store = { ...tempStore, reducerManager: reducerManager(tempStore) };

export interface RootState {
    app: AppState;
    chat: IChatsState;
    collections: ICollectionsState;
    users: UsersState;
    personas: IPersonasState;
    theme: IThemeState;
}

export const getSelectedChatId = (): string => {
    const selectedId = store.getState().chat.selectedId;
    return selectedId;
};

export const getSelectedCollectionId = (): string => {
    return store.getState().collections.selectedId;
};

export type AppDispatch = typeof store.dispatch;

// Function to reset the app state
export const resetState = () => {
    store.dispatch(resetApp());
};
