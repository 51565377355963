import { AddSquare20Regular, ArrowDownload20Regular, Delete20Regular } from '@fluentui/react-icons';
import { FC, useEffect, useState } from 'react';
import { getFriendlyChatName, useChat } from 'modules/core/hooks/useChat';
import { editChatTitle, setSelectedChat } from 'modules/core/redux/chats/chatsSlice';
import { Chats } from 'modules/core/redux/chats/ChatState';
import { DeleteDialog } from 'shared/components/DeleteDialog';
import { CollapsableList } from 'shared/components/list/CollapsableList';
import { CollapsableListItem } from 'shared/components/list/CollapsableListItem';
import { useFile } from 'shared/hooks/useFile';
import { AlertType } from 'shared/models/AlertType';
import { useAppSelector, useAppDispatch } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { isToday, timestampToDateString } from 'shared/utils/TextUtils';

export const ChatList: FC = () => {
    const { chats, selectedId } = useAppSelector((state: RootState) => state.chat);

    const [allChats, setAllChats] = useState<Chats>(chats);
    const [itemBeingDeleted, setItemBeingDeleted] = useState<string | undefined>(undefined);

    const file = useFile();
    const chat = useChat();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Ensure local component state is in line with app state.
        const nonHiddenChats: Chats = {};

        for (const key in chats) {
            const conversation = chats[key];
            const chatName = getFriendlyChatName(conversation).toLocaleUpperCase();

            if (chatName) {
                nonHiddenChats[key] = conversation;
            }
        }

        setAllChats(nonHiddenChats);
    }, [chats, selectedId]);

    const handleAddItem = async () => {
        try {
            const chatName = 'New Chat';

            await chat.createChatSession(chatName);
        } catch (error) {
            dispatch(addAlert({ message: 'Failed to create chat', type: AlertType.Error }));
        }
    };

    const handleDeleteItem = (chatId: string) => {
        return () => {
            void chat.deleteChatSession(chatId);
        };
    };

    const handleDownloadChat = (selectedId: string) => {
        const currentChat = chats[selectedId];
        let content = '';
        currentChat.messages.forEach((message) => {
            content += timestampToDateString(message.dateCreated, true);
            content += '\r\n';

            if (message.agentResponse) {
                content += `Assistant: ${message.agentResponse}`;
            }
            if (message.userPrompt) {
                content += `${message.userName}: ${message.userPrompt}`;
            }

            content += '\r\n\r\n';
        });

        file.createDownloadableFile(content, `${currentChat.title.replaceAll(' ', '-')}.txt`, 'text/plain');
    };

    const handleSaveTitleChange = (chatId: string) => async (title: string) => {
        if (title == '') {
            const errorMessage = `New chat name cannot be blank.`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        } else {
            await chat.editChat(chatId, title).then(() => {
                dispatch(editChatTitle({ id: chatId, newTitle: title }));
            });
        }
    };

    const renderDeleteDialog = () => {
        const chat = Object.values(allChats).find((chat) => chat.id === itemBeingDeleted);
        if (chat == null) {
            return null;
        }

        return (
            <DeleteDialog
                subject={chat.title}
                description={`This action cannot be undone. This will permanently delete the chat and all associated messages.`}
                onConfirm={handleDeleteItem(itemBeingDeleted ?? '')}
                open={!!itemBeingDeleted}
                onClose={() => {
                    setItemBeingDeleted(undefined);
                }}
            />
        );
    };

    return (
        <>
            <CollapsableList
                title="Chats"
                items={Object.values(allChats)}
                renderItem={(chat) => (
                    <CollapsableListItem
                        key={chat.id}
                        id={chat.id}
                        header={getFriendlyChatName(chat)}
                        preview={chat.messages[chat.messages.length - 1]?.agentResponse ?? ''}
                        isSelected={chat.id === selectedId}
                        onClick={() => {
                            dispatch(setSelectedChat(chat.id));
                        }}
                        onEditName={(newName) => {
                            void handleSaveTitleChange(chat.id)(newName);
                        }}
                        extraActions={[
                            {
                                icon: <ArrowDownload20Regular />,
                                label: 'Download Chat History',
                                onClick: () => {
                                    handleDownloadChat(chat.id);
                                },
                            },
                            {
                                icon: <Delete20Regular />,
                                label: 'Delete Chat',
                                onClick: () => {
                                    setItemBeingDeleted(chat.id);
                                },
                            },
                        ]}
                    />
                )}
                filterCriteria={(chat, searchText) => chat.title.toLowerCase().includes(searchText.toLowerCase())}
                sections={[
                    {
                        header: 'Today',
                        predicate: (chat) => isToday(new Date(chat.lastUpdatedTimestamp ?? '')),
                    },
                    {
                        header: 'Last 7 days',
                        predicate: (chat) => {
                            const date = new Date(chat.lastUpdatedTimestamp ?? '');
                            const sevenDaysAgo = new Date();
                            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                            return date >= sevenDaysAgo && !isToday(date);
                        },
                    },
                    {
                        header: 'Older',
                        predicate: (chat) => {
                            const date = new Date(chat.lastUpdatedTimestamp ?? '');
                            const sevenDaysAgo = new Date();
                            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                            return date < sevenDaysAgo;
                        },
                    },
                ]}
                addItem={{
                    action: () => {
                        void handleAddItem();
                    },
                    type: 'button',
                    icon: <AddSquare20Regular />,
                    label: 'Add new chat',
                }}
            />
            {renderDeleteDialog()}
        </>
    );
};
