import {
    addChat,
    addMessageToChatFromUser,
    deleteChat,
    setChats,
    setSelectedChat,
    updateBotResponseStatus,
} from 'modules/core/redux/chats/chatsSlice';
import { IChatSessionState, Chats } from 'modules/core/redux/chats/ChatState';
import { ChatService } from 'modules/core/services/ChatService';
import { useAuth } from 'shared/hooks/useAuth';
import { AskChatRequest, ChatAuthorType, ChatMessageHeader, ChatSettings } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert, updateTokenUsage } from 'shared/redux/features/app/appSlice';
import { getDefaultPersona } from 'shared/utils/PesonaUtils';
import { getErrorDetails } from 'shared/utils/TextUtils';

export interface GetResponseOptions {
    value: string;
    chatId: string;
}

export const useChat = () => {
    const dispatch = useAppDispatch();

    const { chats } = useAppSelector((state: RootState) => state.chat);
    const { personas } = useAppSelector((state: RootState) => state.personas);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { getToken } = useAuth();

    const chatService = new ChatService();
    const defaultChatName = 'New Chat';

    const createChatSession = async (chatName: string): Promise<string | undefined> => {
        try {
            const defaultPersona = getDefaultPersona(personas);

            const result = await chatService.createChatSessionAsync(chatName, defaultPersona.id, await getToken());

            const newChat: IChatSessionState = {
                id: result.chatSession.id,
                title: result.chatSession.title,
                messages: [result.initialAgentMessage],
                lastUpdatedTimestamp: result.chatSession.dateModified,
                input: '',
                botResponseStatus: undefined,
                settings: result.chatSession.settings,
            };

            dispatch(addChat(newChat));
            dispatch(setSelectedChat(result.chatSession.id));

            return newChat.id;
        } catch (e: any) {
            const errorMessage = `Unable to create new chat thread. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }

        return undefined;
    };

    const getResponse = async ({ value, chatId }: GetResponseOptions) => {
        const chatInput: ChatMessageHeader = {
            id: '',
            sessionId: chatId,
            author: ChatAuthorType.OWNER,
            userId: activeUserInfo?.id as string,
            userName: activeUserInfo?.username as string,
            userPrompt: value,
            agentResponse: '',
            dateCreated: new Date().toISOString(),
            dateModified: new Date().toISOString(),
            citations: [],
        };

        dispatch(addMessageToChatFromUser({ message: chatInput, chatId: chatId }));

        const chatRequest: AskChatRequest = {
            sessionId: chatId,
            userPrompt: value,
        };

        try {
            const chatResponse = await chatService.askChatAsync(chatId, chatRequest, await getToken());

            dispatch(updateTokenUsage(chatResponse.tokenUsage));
        } catch (e) {
            const errorDetails = getErrorDetails(e);
            if (errorDetails.includes('Failed to process plan')) {
                // Error should already be reflected in bot response message. Skip alert.
                return;
            }

            const action = 'generate bot response';
            const errorMessage = `Unable to ${action}. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        } finally {
            dispatch(updateBotResponseStatus({ chatId, status: undefined }));
        }
    };

    const loadChats = async () => {
        try {
            const accessToken = await getToken();
            const chatSessions = await chatService.retrieveChatSessionsAsync(accessToken);

            if (chatSessions.results.length > 0) {
                const loadedChats: Chats = {};
                for (const chatSession of chatSessions.results) {
                    // TODO: Move the specifics to a separate API call on load for the chat.
                    const chatMessages = await chatService.retrieveChatMessagesAsync(chatSession.id, accessToken);

                    loadedChats[chatSession.id] = {
                        id: chatSession.id,
                        title: chatSession.title,
                        messages: chatMessages,
                        input: '',
                        botResponseStatus: undefined,
                        settings: chatSession.settings,
                        lastUpdatedTimestamp: chatSession.dateModified,
                    };
                }

                dispatch(setChats(loadedChats));

                // If there are no non-hidden chats, create a new chat
                const nonHiddenChats = Object.values(loadedChats);
                if (nonHiddenChats.length === 0) {
                    await createChatSession(defaultChatName);
                } else {
                    dispatch(setSelectedChat(nonHiddenChats[nonHiddenChats.length - 1].id));
                }
            } else {
                // No chats exist, create first chat window
                await createChatSession(defaultChatName);
            }

            return true;
        } catch (e: any) {
            const errorMessage = `Unable to load chats. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return false;
        }
    };

    const editChat = async (chatId: string, title?: string, settings?: ChatSettings) => {
        try {
            await chatService.updateChatSessionAsync(await getToken(), chatId, title, settings);
        } catch (e: any) {
            const errorMessage = `Error editing chat ${chatId}. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const editPersonaSetting = async (personaId: string, chatId: string) => {
        try {
            const chat = await chatService.retrieveChatSessionAsync(chatId, await getToken());
            const settings = chat.settings;
            settings.personaId = personaId;
            await chatService.updateChatSessionAsync(await getToken(), chatId, undefined, settings);
        } catch (e: any) {
            const errorMessage = `Error editing persona ${personaId} for chatId ${chatId}. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const deleteChatSession = async (chatId: string) => {
        const friendlyChatName = getFriendlyChatName(chats[chatId]);
        await chatService
            .deleteChatSessionAsync(chatId, await getToken())
            .then(() => {
                dispatch(deleteChat(chatId));

                if (Object.values(chats).filter((c) => c.id !== chatId).length === 0) {
                    // If there are no non-hidden chats, create a new chat
                    void createChatSession(defaultChatName);
                }
            })
            .catch((e: any) => {
                const errorDetails = `Details: ${(e as Error).message}`;
                dispatch(
                    addAlert({
                        message: `Unable to delete chat {${friendlyChatName}}. ${errorDetails}`,
                        type: AlertType.Error,
                        onRetry: () => void deleteChatSession(chatId),
                    }),
                );
            });
    };

    return {
        createChatSession,
        loadChats,
        getResponse,
        editChat,
        editPersonaSetting,
        deleteChatSession,
    };
};

export function getFriendlyChatName(convo: IChatSessionState): string {
    const messages = convo.messages;

    // Regex to match the default message timestamp format that is used as the default chat name.
    // The format is: 'Chat Thread @ MM/DD/YYYY, hh:mm:ss AM/PM'.
    const autoGeneratedTitleRegex =
        /Chat Thread @ [0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,4}, [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2} [A,P]M/;

    const firstUserMessage = messages.find((message) => message.author !== ChatAuthorType.AGENT);

    // If the chat title is the default chat timestamp, use the first user message as the title.
    // If no user messages exist, use 'New Chat' as the title.
    const friendlyTitle = autoGeneratedTitleRegex.test(convo.title)
        ? (firstUserMessage?.userPrompt ?? 'New Chat')
        : convo.title;

    // Truncate the title if it is too long
    return friendlyTitle.length > 60 ? friendlyTitle.substring(0, 60) + '...' : friendlyTitle;
}
