import { makeStyles } from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { ChatList } from './ChatList';
import { ChatWindow } from './ChatWindow';

const useClasses = makeStyles({
    container: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.chat);

    return (
        <div className={classes.container}>
            <ChatList />
            {selectedId !== '' && <ChatWindow />}
        </div>
    );
};
