import { Button } from '@fluentui/react-components';
import { AddSquare20Regular, Delete20Regular } from '@fluentui/react-icons';
import { FC, useCallback, useEffect, useState } from 'react';
import { getFriendlyCollectionName, useCollection } from 'modules/core/hooks/useCollection';
import { editCollectionName, setSelectedCollection } from 'modules/core/redux/collections/collectionsSlice';
import { Collections } from 'modules/core/redux/collections/CollectionsState';
import { ICollectionState } from 'modules/core/redux/collections/CollectionState';
import { DeleteDialog } from 'shared/components/DeleteDialog';
import { CollapsableList } from 'shared/components/list/CollapsableList';
import { CollapsableListItem } from 'shared/components/list/CollapsableListItem';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { NewCollectionMenu } from './NewCollectionMenu';

export const CollectionList: FC = () => {
    const { collections, selectedId } = useAppSelector((state: RootState) => state.collections);

    const [allCollections, setAllCollections] = useState<Collections>(collections);
    const [itemBeingDeleted, setItemBeingDeleted] = useState<string | undefined>(undefined);

    const dispatch = useAppDispatch();
    const collection = useCollection();
    const selectedCollection = collections[selectedId] as ICollectionState | undefined;
    const roles = new Set(
        Object.values(allCollections)
            .map((collection) => collection.role)
            .filter((role) => role),
    );

    useEffect(() => {
        // Ensure local component state is in line with app state.
        const nonHiddenCollections: Collections = {};

        for (const key in collections) {
            const collection = collections[key];
            const collectionName = getFriendlyCollectionName(collection).toLocaleUpperCase();

            if (collectionName) {
                nonHiddenCollections[key] = collection;
            }
        }

        setAllCollections(nonHiddenCollections);
    }, [collections]);

    const onDeleteItem = (chatId: string) => {
        return () => {
            void collection.deleteCollection(chatId);
        };
    };

    const onSaveTitleChange = (collectionId: string) => async (title: string) => {
        if (title == '') {
            const errorMessage = `New chat name cannot be blank.`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        } else {
            await collection.editCollection(collectionId, title).then(() => {
                dispatch(editCollectionName({ id: collectionId, newName: title }));
            });
        }
    };

    const preview = useCallback((collection: ICollectionState) => {
        if (collection.documents.length === 0) {
            return 'No documents';
        }

        if (collection.documents.length === 1) {
            return '1 document';
        }

        return `${collection.documents.length} documents`;
    }, []);

    return (
        <>
            <CollapsableList
                title="Collections"
                items={Object.values(allCollections)}
                renderItem={(collection) => (
                    <CollapsableListItem
                        key={collection.id}
                        id={collection.id}
                        header={collection.name}
                        preview={preview(collection)}
                        isSelected={collection.id === selectedId}
                        onClick={() => {
                            dispatch(setSelectedCollection(collection.id));
                        }}
                        onEditName={(newName) => {
                            void onSaveTitleChange(collection.id)(newName);
                        }}
                        extraActions={[
                            {
                                icon: <Delete20Regular />,
                                label: 'Delete Collection',
                                onClick: () => {
                                    setItemBeingDeleted(collection.id);
                                },
                            },
                        ]}
                    />
                )}
                filterCriteria={(collection, searchText) =>
                    collection.name.toLowerCase().includes(searchText.toLowerCase())
                }
                sections={[
                    {
                        header: 'My Private Collections',
                        predicate: (collection) => collection.isPrivate,
                    },
                    ...Array.from(roles).map((role) => ({
                        header: role ?? '',
                        predicate: (collection: ICollectionState) => collection.role === role && !!role,
                    })),
                    {
                        header: 'Public Collections',
                        predicate: (collection) => !collection.isPrivate && !collection.role,
                    },
                ]}
                addItem={{
                    type: 'element',
                    trigger: (
                        <NewCollectionMenu>
                            <Button
                                data-testid="createNewCollectionButton"
                                icon={<AddSquare20Regular />}
                                appearance="transparent"
                            />
                        </NewCollectionMenu>
                    ),
                }}
            />

            <DeleteDialog
                subject={selectedCollection?.name ?? ''}
                description={'This action will remove the collection and any associated documents.'}
                onConfirm={onDeleteItem(itemBeingDeleted ?? '')}
                open={!!itemBeingDeleted}
                onClose={() => {
                    setItemBeingDeleted(undefined);
                }}
            />
        </>
    );
};
