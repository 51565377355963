import {
    Button,
    Image,
    Text,
    makeStyles,
    shorthands,
    tokens,
    Tooltip,
    Select,
    Label,
} from '@fluentui/react-components';
import { Dismiss16Regular, Info24Regular } from '@fluentui/react-icons';
import React, { useEffect, useRef, useState } from 'react';
import { CollectionProgressBar } from 'modules/core/components/collection/CollectionProgressBar';
import { useCollection } from 'modules/core/hooks/useCollection';
import { ICollectionState } from 'modules/core/redux/collections/CollectionState';
import { useFile } from 'shared/hooks/useFile';
import { DocumentHeaderType, DocumentHeader, DocumentStatus } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { addAlert } from 'shared/redux/features/app/appSlice';

const useStyles = makeStyles({
    container: {
        position: 'relative',
        borderRadius: '8px',
        border: `2px dashed ${tokens.colorNeutralStroke1}`,
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalL),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out, opacity 0.3s ease-in-out',
        width: '88%',
    },
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    selectContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    dragging: {
        backgroundColor: tokens.colorNeutralBackground4Pressed,
        opacity: 0.6,
    },
    overlay: {
        position: 'absolute',
        inset: 0,
        backgroundColor: tokens.colorNeutralStroke3,
        opacity: 0.4,
        borderRadius: '8px',
        display: 'none',
    },
    overlayVisible: {
        display: 'block',
    },
    image: {
        maxWidth: '100px',
        maxHeight: '100px',
    },
    uploadButton: {
        marginTop: tokens.spacingVerticalM,
        width: '100%',
    },
    collectionSelect: {
        width: '100%',
        marginBottom: tokens.spacingVerticalM,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'middle',
        alignContent: 'center',
    },
    infoButton: {
        alignItems: 'flex-end',
    },
    importing: {
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalM,
        width: '100%',
    },
    importingText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        fontStyle: 'italic',
    },
    drawerLabel: {
        fontWeight: 600,
        margin: 0,
    },
    icons: {
        padding: '0px',
    },
});

interface FileDroppableImageProps {
    acceptedFileTypes: string[];
    maxFileSize: number;
    type: DropZoneType;
    showUploadButton?: boolean;
    backgroundColor?: string;
}

type DropZoneType =
    | { type: 'image'; image: string; onFileUpload: (file: File) => void }
    | { type: 'file'; onFileUpload: (file: File) => void }
    | { type: 'collection'; onCollectionUpload: (collection: ICollectionState) => void };

export const FileDropZone: React.FC<FileDroppableImageProps> = ({
    type,
    acceptedFileTypes,
    maxFileSize,
    backgroundColor = tokens.colorNeutralBackground1,
    showUploadButton = true,
}) => {
    const [dragging, setDragging] = useState<boolean>(false);
    const [collection, setCollection] = useState<ICollectionState | undefined>(undefined);
    const [uploadedDocuments, setUploadedDocuments] = useState<string[]>([]);

    const { handleImport } = useFile();
    const { refreshCollection } = useCollection();

    const styles = useStyles();
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const collections = useAppSelector((state) => state.collections.collections);

    const validCollections = Object.values(collections)
        .filter((collection) => collection.type !== DocumentHeaderType.SHARE_POINT && collection.isPrivate)
        .sort((a, b) => a.name.localeCompare(b.name));

    const documentsBeingImported = validCollections
        .flatMap((collection) => collection.documents)
        .filter((document) => uploadedDocuments.find((path) => path.includes(document.path)) !== undefined)
        .reduce<DocumentHeader[]>((acc, document) => {
            const existingDocument = acc.find((d) => d.path === document.path);

            if (
                !existingDocument ||
                ((existingDocument.status === DocumentStatus.COMPLETED ||
                    existingDocument.status === DocumentStatus.ERROR) &&
                    document.status !== DocumentStatus.COMPLETED)
            ) {
                return [...acc, document];
            }

            return [...acc.filter((d) => d.path !== document.path), document];
        }, []);

    useEffect(() => {
        if (type.type === 'collection') {
            setCollection(validCollections[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (type.type === 'collection') {
            const interval = setInterval(() => {
                documentsBeingImported.forEach((document) => {
                    if (document.status !== DocumentStatus.COMPLETED && document.status !== DocumentStatus.ERROR) {
                        void refreshCollection(document.collectionId);
                    }
                });
            }, 5000);

            return () => {
                clearInterval(interval);
            };
        }

        return;
    }, [validCollections, type, collection, refreshCollection, uploadedDocuments, documentsBeingImported]);

    const handleFileValidation = (file: File) => {
        if (!acceptedFileTypes.includes(file.type)) {
            dispatch(
                addAlert({ message: 'Invalid file type. Please upload a valid image file.', type: AlertType.Warning }),
            );
            return false;
        }

        if (file.size > maxFileSize) {
            dispatch(
                addAlert({
                    message: `File size should be less than ${maxFileSize / 1024 / 1024} MB.`,
                    type: AlertType.Warning,
                }),
            );
            return false;
        }

        return true;
    };

    const handleFileUpload = (file: File) => {
        if (handleFileValidation(file)) {
            if (type.type === 'collection' && collection) {
                void handleImport(collection.id, { type: 'file', file }).then((document) => {
                    if (document) {
                        type.onCollectionUpload(collection);
                        setUploadedDocuments((prev) => [...prev, ...document.results.map((result) => result.path)]);
                    }
                });
            } else if (type.type === 'file' || type.type === 'image') {
                type.onFileUpload(file);
            }
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0] as File | undefined;
        if (file) {
            handleFileUpload(file);
        }
    };

    const handleHideProgressItem = (document: DocumentHeader) => () => {
        setUploadedDocuments((prev) => prev.filter((path) => path !== document.path));
    };

    return (
        <div className={styles.outerContainer}>
            {type.type === 'collection' && (
                <div className={styles.selectContainer}>
                    <p className={styles.drawerLabel}>Upload Additional Documents</p>
                    <div className={styles.header}>
                        <Label>Collection</Label>
                        <Tooltip content="Select a collection to upload a file into" relationship="label">
                            <Button
                                className={styles.infoButton}
                                icon={<Info24Regular />}
                                appearance="transparent"
                                aria-label="Info: Select a collection to upload a file into"
                            />
                        </Tooltip>
                    </div>
                    <Select
                        className={styles.collectionSelect}
                        defaultValue={validCollections[0]?.id}
                        onChange={(_e, option) => {
                            setCollection(collections[option.value]);
                        }}
                    >
                        {validCollections.map((collection, index) => (
                            <option key={index} value={collection.id}>
                                {collection.name}
                            </option>
                        ))}
                    </Select>
                </div>
            )}

            <div
                className={`${styles.container} ${dragging ? styles.dragging : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
                style={{ backgroundColor }}
            >
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept={acceptedFileTypes.join(',')}
                    onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            handleFileUpload(file);
                        }
                    }}
                />

                {type.type === 'image' && <Image src={type.image} alt="Image Preview" className={styles.image} />}

                {type.type === 'file' || (type.type === 'collection' && <Text>Drag & Drop or Click to Upload</Text>)}

                <div className={`${styles.overlay} ${dragging ? styles.overlayVisible : ''}`} />
            </div>

            {showUploadButton && (
                <Tooltip content="Upload an image" relationship="label">
                    <Button
                        appearance="secondary"
                        className={styles.uploadButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            fileInputRef.current?.click();
                        }}
                    >
                        Upload
                    </Button>
                </Tooltip>
            )}

            {type.type === 'collection' &&
                collection &&
                documentsBeingImported.map((document) => (
                    <div key={document.id} className={styles.importing}>
                        <div className={styles.header}>
                            <Text className={styles.importingText}>{document.name}</Text>
                            <Tooltip content="Hide progress item" relationship="label">
                                <Button
                                    appearance="transparent"
                                    icon={<Dismiss16Regular onClick={handleHideProgressItem(document)} />}
                                    aria-label="Hide progress item"
                                    className={styles.icons}
                                />
                            </Tooltip>
                        </div>
                        <CollectionProgressBar status={document.status} />
                    </div>
                ))}
        </div>
    );
};
