import {
    addCollection,
    deleteCollection as deleteCollectionWithId,
    deleteDocumentInCollection,
    setCollectionDocuments,
    setCollections,
    setSelectedCollection,
} from 'modules/core/redux/collections/collectionsSlice';
import { Collections } from 'modules/core/redux/collections/CollectionsState';
import { ICollectionState } from 'modules/core/redux/collections/CollectionState';
import { CollectionService } from 'modules/core/services/CollectionService';
import { useAuth } from 'shared/hooks/useAuth';
import { CreateCollectionResponse, DocumentHeaderType } from 'shared/libs/generated';
import { AlertType } from 'shared/models/AlertType';
import { ICollectionUser } from 'shared/models/CollectionUser';
import { useAppDispatch, useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { addAlert } from 'shared/redux/features/app/appSlice';
import { getErrorDetails } from 'shared/utils/TextUtils';

export const useCollection = () => {
    const dispatch = useAppDispatch();

    const { getToken } = useAuth();
    const { collections } = useAppSelector((state: RootState) => state.collections);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const collectionService = new CollectionService();

    const userId = activeUserInfo?.id ?? '';
    const fullName = activeUserInfo?.username ?? '';
    const loggedInUser = { id: userId, fullName };

    const getCollectionUserById = (id: string, users: ICollectionUser[]) => {
        return users.find((user) => user.id === id);
    };

    const createCollection = async (isPrivate: boolean, role?: string) => {
        const collectionName = `Collection @ ${new Date().toLocaleString()}`;
        try {
            return await collectionService
                .createCollectionAsync(collectionName, isPrivate, await getToken(), role)
                .then((result: CreateCollectionResponse) => {
                    const newCollection: ICollectionState = {
                        id: result.id,
                        name: result.name,
                        documents: [],
                        lastUpdatedTimestamp: result.dateModified,
                        users: [loggedInUser],
                        uploadStatus: undefined,
                        isPrivate: isPrivate,
                        role: role,
                        type: DocumentHeaderType.FILE_UPLOAD,
                    };

                    dispatch(addCollection(newCollection));

                    return newCollection;
                });
        } catch (e: any) {
            const errorMessage = `Unable to create new collection. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }

        return undefined;
    };

    const refreshCollection = async (collectionId: string) => {
        try {
            const accessToken = await getToken();

            const documents = await collectionService.retrieveDocumentsByCollectionIdAsync(collectionId, accessToken);

            dispatch(setCollectionDocuments({ documents: documents.results, collectionId }));
        } catch (e: any) {
            const errorMessage = `Unable to create new collection. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const loadCollections = async () => {
        try {
            const accessToken = await getToken();
            const allCollections = await collectionService.retrieveCollectionsAsync(accessToken);

            if (allCollections.results.length > 0) {
                const loadedCollections: Collections = {};
                for (const currentCollection of allCollections.results) {
                    const documents = await collectionService.retrieveDocumentsByCollectionIdAsync(
                        currentCollection.id,
                        accessToken,
                    );

                    const users = [
                        {
                            id: currentCollection.userId,
                            fullName: currentCollection.userName,
                        },
                    ];

                    loadedCollections[currentCollection.id] = {
                        id: currentCollection.id,
                        name: currentCollection.name,
                        users: users,
                        documents: documents.results,
                        uploadStatus: undefined,
                        isPrivate: currentCollection.isPrivate,
                        role: currentCollection.role,
                        lastUpdatedTimestamp: currentCollection.dateModified,
                        type:
                            currentCollection.type == DocumentHeaderType.FILE_UPLOAD ||
                            currentCollection.type == DocumentHeaderType.SHARE_POINT
                                ? currentCollection.type
                                : DocumentHeaderType.FILE_UPLOAD,
                    };
                }

                dispatch(setCollections(loadedCollections));

                // If there are no non-hidden collections, create a new collection
                const nonHiddenCollections = Object.values(loadedCollections);
                if (nonHiddenCollections.length === 0) {
                    await createCollection(true); // TODO: Pass through isPrivate
                } else {
                    dispatch(setSelectedCollection(nonHiddenCollections[0].id));
                }
            } else {
                // No collections exist, create first collection window
                await createCollection(true); // TODO: Pass through isPrivate
            }

            return true;
        } catch (e: any) {
            const errorMessage = `Unable to load collections. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return false;
        }
    };

    const editCollection = async (collectionId: string, name: string) => {
        try {
            await collectionService.updateCollectionAsync(collectionId, name, await getToken());
        } catch (e: any) {
            const errorMessage = `Error editing collection ${collectionId}. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const deleteCollection = async (collectionId: string) => {
        const collection = collections[collectionId];
        const friendlyCollectionName = getFriendlyCollectionName(collection);
        await collectionService
            .deleteCollectionAsync(collectionId, await getToken())
            .then(() => {
                dispatch(deleteCollectionWithId(collectionId));

                if (Object.values(collections).filter((c) => c.id !== collectionId).length === 0) {
                    // If there are no non-hidden collections, create a new collection
                    void createCollection(collection.isPrivate);
                }
            })
            .catch((e: any) => {
                const errorDetails = `Details: ${(e as Error).message}`;
                dispatch(
                    addAlert({
                        message: `Unable to delete collection {${friendlyCollectionName}}. ${errorDetails}`,
                        type: AlertType.Error,
                        onRetry: () => void deleteCollection(collectionId),
                    }),
                );
            });
    };

    const deleteCollectionDocument = async (collectionId: string, documentId: string) => {
        await collectionService
            .deleteDocumentAsync(collectionId, documentId, await getToken())
            .then(() => {
                dispatch(deleteDocumentInCollection({ collectionId, documentId }));
            })
            .catch((e: any) => {
                const errorDetails = `Details: ${(e as Error).message}`;
                dispatch(
                    addAlert({
                        message: `Unable to delete document. ${errorDetails}`,
                        type: AlertType.Error,
                        onRetry: () => void deleteCollectionDocument(collectionId, documentId),
                    }),
                );
            });
    };

    const getBlobKeyForUpload = async (filePath: string, documentId: string) => {
        try {
            const blobKey = await collectionService.retrieveBlobKeyForUploadAsync(
                filePath,
                documentId,
                await getToken(),
            );

            return blobKey;
        } catch (e: any) {
            const errorMessage = `Error getting document. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            return '';
        }
    };

    const getBlobKeyForContent = async (fileName: string) => {
        try {
            const blobKey = await collectionService.retrieveBlobKeyForContentAsync(fileName, await getToken());

            return blobKey;
        } catch (e: any) {
            const errorMessage = `Error getting document. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            return '';
        }
    };

    return {
        getCollectionUserById,
        createCollection,
        refreshCollection,
        loadCollections,
        editCollection,
        deleteCollection,
        deleteCollectionDocument,
        getBlobKeyForUpload,
        getBlobKeyForContent,
    };
};

export function getFriendlyCollectionName(collection: ICollectionState): string {
    const documents = collection.documents;

    // Regex to match the default message timestamp format that is used as the default collection name.
    // The format is: 'Collection @ MM/DD/YYYY, hh:mm:ss AM/PM'.
    const autoGeneratedTitleRegex =
        /Collection @ [0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,4}, [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2} [A,P]M/;

    // If the collection title is the default collection timestamp, use the first document as the title.
    // If no document exists, use 'New Collection' as the title.
    const friendlyTitle = autoGeneratedTitleRegex.test(collection.name)
        ? (documents[0]?.name ?? 'New Collection')
        : collection.name;

    // Truncate the title if it is too long
    return friendlyTitle.length > 60 ? friendlyTitle.substring(0, 60) + '...' : friendlyTitle;
}
