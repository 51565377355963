/* eslint-disable @typescript-eslint/no-unnecessary-condition */
// redux/dynamicReducerManager.ts
import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Action, Store, UnknownAction } from 'redux';
import { rootReducerKeyValues } from './rootReducer';
import { RootState } from './store';

export interface ReducerManager {
    addReducer: (key: string, reducer: Reducer<any, Action>) => void;
}

export const reducerManager = (store: Store): ReducerManager => {
    const reducers: Record<string, Reducer<RootState>> = {};

    const addReducer = (key: string, reducer: Reducer<any, Action>) => {
        if (reducers[key]) return;
        reducers[key] = reducer;
        store.replaceReducer(
            combineReducers({
                ...rootReducerKeyValues,
                ...(reducers as UnknownAction),
            }),
        );
    };

    return { addReducer };
};
