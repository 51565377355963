import { makeStyles } from '@fluentui/react-components';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { formatChatTextContent } from 'shared/utils/TextUtils';

const useClasses = makeStyles({
    content: {
        wordBreak: 'break-word',
    },
});

interface ChatHistoryTextContentProps {
    userPrompt?: string;
    agentResponse?: string;
}

const parseMarkdownToHtml = (answer: string | undefined): string => {
    // Return an empty string if the answer is undefined
    if (!answer) return '';

    try {
        // Parse the answer to Markdown and ensure the result is a string
        const parsedMarkdown: string = marked.parse(answer) as string;

        // Sanitize the parsed Markdown and return it
        return DOMPurify.sanitize(parsedMarkdown);
    } catch (error) {
        // Log the error and return an empty string in case of failure
        console.error('Error parsing or sanitizing markdown:', error);
        console.info('Raw answer:', answer);
        return answer;
    }
};

export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = ({ userPrompt, agentResponse }) => {
    const classes = useClasses();

    return (
        <div className={classes.content}>
            {!!userPrompt && (
                <ReactMarkdown remarkPlugins={[remarkGfm]} components={{ p: 'span' }}>
                    {formatChatTextContent(userPrompt)}
                </ReactMarkdown>
            )}
            {!!agentResponse && (
                <div
                    className="answerText"
                    dangerouslySetInnerHTML={{ __html: parseMarkdownToHtml(agentResponse) }}
                ></div>
            )}
        </div>
    );
};
