import { Link } from '@fluentui/react';
import {
    // Button,
    Dialog,
    DialogBody,
    // DialogContent,
    DialogOpenChangeData,
    DialogSurface,
    DialogTitle,
    DialogActions,
    DialogTrigger,
    makeStyles,
    // shorthands,
    tokens,
} from '@fluentui/react-components';
import React from 'react';
import { useCollection } from 'modules/core/hooks/useCollection';
import { Dismiss20 } from 'shared/components/BundledIcons';
import { CitationSource } from 'shared/libs/generated';
import { SharedStyles } from 'shared/styles';

const useClasses = makeStyles({
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
    },
    content: {
        height: '100%',
        maxHeight: '100px',
        ...SharedStyles.scroll,
    },
    footer: {
        paddingTop: tokens.spacingVerticalL,
    },
    iFrame: {
        border: 'none',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconButton: {
        cursor: 'pointer',
    },
    viewLink: {
        width: '124px',
    },
});

interface ICitationDialogProps {
    open: boolean;
    closeDialog: () => void;
    activeCitation?: CitationSource;
}

export const CitationDialog: React.FC<ICitationDialogProps> = ({ open, closeDialog, activeCitation }) => {
    const classes = useClasses();
    // const dialogClasses = useDialogClasses();
    const collection = useCollection();

    const [isError, setIsError] = React.useState<boolean>(false);
    const [citationUrl, setCitationUrl] = React.useState<string>();

    React.useEffect(() => {
        if (!open) {
            setCitationUrl('');
            setIsError(false);
            return;
        }

        if (isError || !activeCitation?.sourceFile || citationUrl) {
            return;
        }

        void collection
            .getBlobKeyForContent(activeCitation.sourceFile)
            .then((url) => {
                if (!url) {
                    setCitationUrl('NOTFOUND');
                    return;
                }

                setCitationUrl(url);
            })
            .catch(() => {
                setIsError(true);
            });
    }, [activeCitation?.collectionId, activeCitation?.sourceFile, citationUrl, collection, isError, open]);

    const onFileClick = async () => {
        const citationUrl = activeCitation?.sourceDocument ?? '';
        if (!citationUrl) {
            return;
        }

        // Regular expression to match and capture the parts of the path
        const regex = /^([a-f0-9-]+)\/([a-f0-9-]+)\/(.*?)(-chunk\(\d+-\d+\))?\.pdf$/;

        // Use the regex to replace the path with the desired format
        const transformedPath = citationUrl.replace(regex, (_match, guid1, _guid2, fileName) => {
            return `${guid1}/${fileName}.pdf`;
        });

        const documentId = activeCitation?.sourceFile.split('/')[1] ?? '';
        const url = await collection.getBlobKeyForUpload(transformedPath, documentId);
        window.open(url, '_blank', 'rel=noopener noreferrer');
    };

    //
    const substrings = ['#navpanes=0'];

    const amendedCitationUrl = citationUrl + substrings.join('');

    return (
        <Dialog
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                if (!data.open) closeDialog();
            }}
        >
            <DialogSurface>
                <DialogBody className={classes.root}>
                    <div className={classes.header}>
                        <DialogTitle>Citation</DialogTitle>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Dismiss20
                                    type="button"
                                    className={classes.iconButton}
                                    data-testid="userSettingsCloseButton"
                                />
                            </DialogTrigger>
                        </DialogActions>
                    </div>

                    {citationUrl === 'NOTFOUND' && (
                        <div>
                            <p>The document could not be found. It might have been deleted or relocated.</p>
                        </div>
                    )}
                    {citationUrl !== 'NOTFOUND' && !!citationUrl && (
                        <>
                            <Link
                                href="#"
                                title="View document"
                                underline
                                className={classes.viewLink}
                                onClick={() => void onFileClick()}
                            >
                                View Full Document
                            </Link>
                            {!isError && (
                                <iframe
                                    className={classes.iFrame}
                                    title="Citation"
                                    src={amendedCitationUrl}
                                    height="100%"
                                />
                            )}
                        </>
                    )}
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
