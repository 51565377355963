import { useMemo } from 'react';
import { TreeNode } from 'react-dropdown-tree-select';
import { Collections } from 'modules/core/redux/collections/CollectionsState';
import { ICollectionState } from 'modules/core/redux/collections/CollectionState';
import { useAppSelector } from 'shared/redux/app/hooks';
import { RootState } from 'shared/redux/app/store';
import { getFormattedRoleName } from 'shared/utils/CollectionUtils';
import { getDefaultPersona } from 'shared/utils/PesonaUtils';

interface Props {
    selectedDocumentNodes: string[];
}

export const useTree = ({ selectedDocumentNodes }: Props) => {
    const { collections } = useAppSelector((state: RootState) => state.collections);
    const { personas } = useAppSelector((state: RootState) => state.personas);
    const { chats, selectedId } = useAppSelector((state: RootState) => state.chat);

    const keys = useMemo(() => {
        return Object.keys(collections);
    }, [collections]);

    const privateCollections: TreeNode = useMemo(() => {
        const isPrivateCollectionsChecked = selectedDocumentNodes.includes('private-collections');

        const allPrivateCollections = keys
            .filter((key) => {
                const currentCollection = collections[key];
                return currentCollection.isPrivate;
            })
            .map((key) => mapToCollectionNode(collections, selectedDocumentNodes, key, isPrivateCollectionsChecked))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

        return {
            label: 'Private Collections',
            value: 'private-collections',
            expanded: true,
            children: allPrivateCollections,
        };
    }, [collections, keys, selectedDocumentNodes]);

    const publicCollections: TreeNode = useMemo(() => {
        const isPublicCollectionsChecked = selectedDocumentNodes.includes('public-collections');

        const allPublicCollections = keys
            .filter((key) => {
                const currentCollection = collections[key];
                return !currentCollection.isPrivate && !currentCollection.role;
            })
            .map((key) => mapToCollectionNode(collections, selectedDocumentNodes, key, isPublicCollectionsChecked))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

        return {
            label: 'Public Collections',
            value: 'public-collections',
            expanded: true,
            children: allPublicCollections,
        };
    }, [collections, keys, selectedDocumentNodes]);

    const otherCollections: TreeNode[] = useMemo(() => {
        const sortedCollections: TreeNode[] = [];
        const collectionGroups: Array<{ role: string; name: string; collections: ICollectionState[] }> = [];

        keys.forEach((key) => {
            const currentCollection = collections[key];
            if (currentCollection.isPrivate || !currentCollection.role) {
                return;
            }

            const existingCollectionGroup = collectionGroups.find((x) => x.role === currentCollection.role);
            if (!existingCollectionGroup) {
                collectionGroups.push({
                    role: currentCollection.role ?? '',
                    name: getFormattedRoleName(currentCollection.role) ?? '',
                    collections: [currentCollection],
                });
                return;
            }

            existingCollectionGroup.collections.push(currentCollection);
        });

        collectionGroups.forEach((collectionGroup) => {
            const isOtherCollectionsChecked = selectedDocumentNodes.includes(`${collectionGroup.role}-collections`);

            const allOtherCollections = collectionGroup.collections
                .map((collection) =>
                    mapToCollectionNode(collections, selectedDocumentNodes, collection.id, isOtherCollectionsChecked),
                )
                .sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });

            sortedCollections.push({
                label: `${collectionGroup.name} Collections`,
                value: `${collectionGroup.role}-collections`,
                expanded: true,
                children: allOtherCollections,
            });
        });

        return sortedCollections;
    }, [collections, keys, selectedDocumentNodes]);

    const allCollections: TreeNode[] = useMemo(() => {
        return [privateCollections, ...otherCollections, publicCollections];
    }, [privateCollections, publicCollections, otherCollections]);

    const settings = useMemo(() => {
        const collectionIds = selectedDocumentNodes
            .filter((x) => x.startsWith('collection..'))
            .map((x) => x.split('..')[1]);

        const selectedDocuments = selectedDocumentNodes
            .filter((x) => x.startsWith('document..'))
            .map((x) => {
                const splits = x.split('..');
                return {
                    documentId: splits[1],
                    collectonId: splits[2],
                };
            });

        const documentIds: string[] = [];

        selectedDocuments.forEach((selectedDocument) => {
            if (collectionIds.includes(selectedDocument.collectonId)) {
                return;
            }

            documentIds.push(selectedDocument.documentId);
        });

        const isAllPrivateCollectionsSelected = selectedDocumentNodes.find((x) => x === 'private-collections');
        if (isAllPrivateCollectionsSelected) {
            keys.forEach((key) => {
                if (collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        otherCollections.forEach((otherCollection) => {
            const isAllOtherCollectionsSelected = selectedDocumentNodes.find((x) => x === otherCollection.value);
            if (isAllOtherCollectionsSelected) {
                keys.forEach((key) => {
                    if (`${collections[key].role}-collections` === otherCollection.value) {
                        collectionIds.push(key);
                    }
                });
            }
        });

        const isAllPublicCollectionsSelected = selectedDocumentNodes.find((x) => x === 'public-collections');
        if (isAllPublicCollectionsSelected) {
            keys.forEach((key) => {
                if (!collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        let personaId = chats[selectedId].settings.personaId;
        if (!personaId) {
            personaId = getDefaultPersona(personas).id;
        }

        return {
            collectionIds: collectionIds,
            documentIds: documentIds,
            personaId: personaId,
        };
    }, [selectedDocumentNodes, otherCollections, chats, selectedId, keys, collections, personas]);

    const allDocuments: TreeNode[] = useMemo(() => {
        const allDocuments = keys
            .map((key) => {
                return collections[key].documents.map((document) => {
                    return {
                        value: `document..${document.id}..${document.collectionId}`,
                        label: document.name,
                        checked: selectedDocumentNodes.find((x) => x.includes(document.id)) ? true : false,
                    };
                });
            })
            .flat()
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

        return allDocuments;
    }, [collections, keys, selectedDocumentNodes]);

    return {
        privateCollections,
        publicCollections,
        otherCollections,
        allCollections,
        allDocuments,
        settings,
    };
};

function mapToCollectionNode(
    collections: Collections,
    selectedDocumentNodes: string[],
    key: string,
    isParentChecked: boolean,
) {
    const currentCollection = collections[key];

    const isCollectionChecked =
        isParentChecked || selectedDocumentNodes.find((x) => x.includes(`collection..${key}`)) ? true : false;

    const children: TreeNode[] = currentCollection.documents
        .map((document) => {
            return {
                value: `document..${document.id}..${document.collectionId}`,
                label: document.name,
                checked:
                    isCollectionChecked || selectedDocumentNodes.find((x) => x.includes(document.id)) ? true : false,
            };
        })
        .sort((a, b) => {
            return a.label.localeCompare(b.label);
        });

    return {
        label: `${currentCollection.name} (${currentCollection.documents.length})`,
        value: `collection..${key}`,
        checked: isCollectionChecked,
        expanded: isCollectionChecked || children.find((x) => x.checked) ? true : false,
        children: children,
    };
}
