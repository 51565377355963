import { ChatMessageHeader, ChatSettings } from 'shared/libs/generated';

export interface IChatSessionState {
    id: string;
    title: string;
    messages: ChatMessageHeader[];
    lastUpdatedTimestamp?: string;
    input: string;
    botResponseStatus: string | undefined;
    settings: ChatSettings;
}

export interface IHtmlParsedAnswer {
    answerHtml: string;
    answerText: string;
    citations: string[];
    followupQuestions: string[];
}

export type Chats = Record<string, IChatSessionState>;

export interface IChatsState {
    chats: Chats;
    selectedId: string;
}

export const initialState: IChatsState = {
    chats: {},
    selectedId: '',
};

export interface IChatTitleChange {
    id: string;
    newTitle: string;
}

export interface IChatInputChange {
    id: string;
    newInput: string;
}

export interface IChatSettingsChange {
    id: string;
    settings: ChatSettings;
}
